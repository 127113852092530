<template>
  <form
    id="di-form-carga"
    data-parsley-validate
    v-bind:class="{ 'no-click': noClick }"
  >
    <div class="form-layout form-layout-2 pd-b-40">
      <div class="report-summary-header mg-t-0 mg-b-10">
        <div>
          <h4 class="tx-inverse mg-b-3">
            <i class="fa fa-archive mg-r-3"></i> Informações de Carga
          </h4>
          <p class="mg-b-0">
            <i class="icon ion-information-circled mg-r-3"></i> Informação de
            Carga, Local de Armazenamento, Armazéns, Volumes, Valores e Rateio
            de Acréscimos.
          </p>
        </div>
        <div></div>
      </div>

      <hr/>

      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="form-group bd-r-0-force">
            <label class="form-control-label"> País de Procedência </label>
            <v-select
              v-model="model.codigoPaisProcedenciaCarga"
              label="value"
              :options="codigosPaises"
              name="codigoPaisProcedenciaCarga"
            >
              <template slot="option" slot-scope="option">
                {{ option.value }}
              </template>
            </v-select>
            <span
              v-show="errors.has('codigoPaisProcedenciaCarga')"
              class="parsley-errors-list filled"
            >Bandeira é um campo obrigatório.</span
            >
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group bd-r-0-force">
            <label class="form-control-label"> Data da Chegada </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker lang="pt-br"
                             v-model="model.dataChegada"
                             name="dataChegada"
                             class="form-control"
                             format="DD/MM/YYYY"
                             placeholder=""
                ></date-picker>
              </div>
            </div>
            <span
              v-show="errors.has('dataChegada')"
              class="parsley-errors-list filled"
            >Data da Chegada é um campo obrigatório.</span
            >
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label class="form-control-label"> URF de Entrada no País </label>
            <v-select
              v-model="model.codigoUrfCargaEntrada"
              :on-search="getCodigoURFs"
              label="descricao"
              :options="listOfURFs"
              name="codigoUrfCargaEntrada"
            >
              <template slot="no-options">
                URF de Entrada não encontrado
              </template>
            </v-select>
            <span
              v-show="errors.has('codigoUrfCargaEntrada')"
              class="parsley-errors-list filled"
            >URF de Entrada no País é um campo obrigatório.</span
            >
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group bd-t-0-force bd-r-0-force">
            <label class="form-control-label"> Agente do Transportador </label>
            <the-mask
              class="form-control"
              v-model="model.numeroAgenteCarga"
              name="numeroAgenteCarga"
              :mask="['###.###.###-##', '##.###.###/####-##']"
              v-validate="'required'"
            />
            <span
              v-show="errors.has('numeroAgenteCarga')"
              class="parsley-errors-list filled"
            >Agente do Transportador é um campo obrigatório.</span
            >
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group bd-t-0-force bd-r-0-force">
            <label class="form-control-label"> Peso Bruto (kg) </label>
            <money
              name="cargaPesoBruto"
              class="form-control"
              v-model="model.cargaPesoBruto"
              v-bind="kg"
            ></money>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group bd-t-0-force">
            <label class="form-control-label"> Peso Líquido (kg) </label>
            <money
              name="cargaPesoLiquido"
              class="form-control"
              v-model="model.cargaPesoLiquido"
              v-bind="kg"
            ></money>
            <span
              v-show="errors.has('cargaPesoLiquido')"
              class="parsley-errors-list filled"
            >Peso Líquido (kg) é um campo obrigatório.</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4" v-if="model.codigoUrfDespacho != null">
          <label class="section-title mg-t-20"> Local de Armazenamento </label>

          <div class="row no-gutters">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-control-label"> Recinto </label>
                <v-select
                  v-model="model.codigoRecintoAlfandegado"
                  :on-search="getCodigoRecintos"
                  label="descricao"
                  :options="listOfRecintosAlfandegados"
                  name="codigoRecintoAlfandegado"
                  @change="getCodigosSetoreArmazenamentos($event)"
                >
                  <template slot="no-options">
                    Recinto não encontrado
                  </template>
                </v-select>
                <span
                  v-show="errors.has('codigoRecintoAlfandegado')"
                  class="parsley-errors-list filled"
                >Recinto é um campo obrigatório.</span
                >
              </div>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-md-12">
              <div class="form-group bd-t-0-force">
                <label class="form-control-label"> Setor </label>
                <v-select
                  v-model="model.codigoSetorArmazenamento"
                  label="codigo"
                  :options="listOfSetoreArmazenamento"
                  name="codigoSetorArmazenamento"
                >
                  <template slot="option" slot-scope="option">
                    <strong>{{ option.codigo }}</strong>
                    {{ option.descricao }}
                  </template>
                </v-select>
                <span
                  v-show="errors.has('codigoSetorArmazenamento')"
                  class="parsley-errors-list filled"
                >Setor é um campo obrigatório.</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <label class="section-title mg-t-20"> ARMAZÉNS </label>
          <table class="table mg-b-0 bd">
            <thead class="thead-colored">
            <tr>
              <th class="tx-center">Descrição</th>
              <th class="tx-center">Ações</th>
            </tr>
            </thead>
            <tbody>
            <template
              v-if="
                  model.armazem !== null &&
                  model.armazem !== undefined &&
                  model.armazem.length > 0
                "
            >
              <tr-inline
                v-for="(item, index) in model.armazem"
                :key="'embalagem-' + index"
                :row="item"
                @save="updateArmazem"
              >
                <td-inline
                  placeholder="Armazém"
                  v-model="item.nomeArmazemCarga"
                />

                <template slot="actions">
                  <a
                    href="#"
                    @click.prevent="removeArmazem(item)"
                    class="btn btn-danger btn-icon rounded-circle"
                  >
                    <div><i class="icon ion-trash-a"></i></div>
                  </a>
                </template>
              </tr-inline>
            </template>

            <template
              v-if="
                  this.model.numeroRegistro == null ||
                  this.model.numeroRegistro == undefined
                "
            >
              <tr-inline
                class="new-item"
                :isNew="true"
                :row="newArmazem"
                @save="createArmazem"
              >
                <td-inline
                  placeholder="Armazém"
                  v-model="newArmazem.nomeArmazemCarga"
                />
              </tr-inline>
            </template>
            </tbody>
          </table>
        </div>

        <div class="col-md-4">
          <label class="section-title mg-t-20"> Volumes </label>
          <table class="table mg-b-0 bd">
            <thead class="thead-colored">
            <tr>
              <th class="tx-center wd-50-p">Embalagem</th>
              <th class="tx-center">Quantidade</th>
              <th class="tx-center wd-100">Ações</th>
            </tr>
            </thead>
            <tbody>
            <template
              v-if="
                  model.embalagem !== null &&
                  model.embalagem !== undefined &&
                  model.embalagem.length > 0
                "
            >
              <tr-inline
                v-for="(item, index) in model.embalagem"
                :key="'embalagem-' + index"
                :row="item"
                @save="updateEmbalagem"
              >
                <td-inline
                  placeholder="Tipo de Embalagem"
                  v-model="item.codigoTipoEmbalagemCarga"
                  type="select"
                  selectType="key|{key-value}"
                  :options="codigosTipoEmbalagens"
                />
                <td-inline
                  placeholder="Quantidade"
                  type="string"
                  v-model="item.quantidadeVolumeCarga"
                />

                <template slot="actions">
                  <a
                    href="#"
                    @click.prevent="removeEmbalagem(item)"
                    class="btn btn-danger btn-icon rounded-circle"
                  >
                    <div><i class="icon ion-trash-a"></i></div>
                  </a>
                </template>
              </tr-inline>
            </template>

            <template
              v-if="
                  this.model.numeroRegistro == null ||
                  this.model.numeroRegistro == undefined
                "
            >
              <tr-inline
                class="new-item"
                :isNew="true"
                :row="newEmbalagem"
                @save="createEmbalagem"
              >
                <td-inline
                  placeholder="Tipo de Embalagem"
                  v-model="newEmbalagem.codigoTipoEmbalagemCarga"
                  type="select"
                  selectType="key|value"
                  :options="codigosTipoEmbalagens"
                />
                <td-inline
                  placeholder="Quantidade"
                  v-model="newEmbalagem.quantidadeVolumeCarga"
                />
              </tr-inline>
            </template>
            </tbody>
          </table>
        </div>
      </div>

      <hr class="mg-b-25"/>

      <div class="row no-gutters mg-t-20">
        <div class="col-md-3">
          <label class="section-title mg-t-0">
            Mercadorias no Local de Embarque</label
          >
          <div class="row no-gutters">
            <div class="col-md-6">
              <div class="form-group bd-r-0-force ht-60-force">
                <label class="form-control-label"> Valor na Moeda </label>
                <money
                  name="valorTotalDespesasMoedaNegociada"
                  class="form-control"
                  v-model="model.valorTotalDespesasMoedaNegociada"
                  v-bind="money"
                ></money>
                <span
                  v-show="errors.has('valorTotalDespesasMoedaNegociada')"
                  class="parsley-errors-list filled"
                >Valor na Moeda é um campo obrigatório.</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group bd-r-0-force ht-60-force">
                <label class="form-control-label"> Moeda </label>
                <v-select
                  v-model="model.codigoMoedaLocalEmbarque"
                  label="name"
                  :options="coinsMapComplete"
                  name="codigoMoedaLocalEmbarque"
                  @change="calcularMoedaLocalEmbarque"
                  class="wd-90p"
                >
                  <template slot="option" slot-scope="option">
                    <strong>{{ option.code }}</strong> {{ option.name }}
                  </template>
                </v-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group bd-t-0-force bd-r-0-force ht-60-force">
                <label class="form-control-label"> Valor em Real (R$) </label>
                <money
                  name="valorTotalDespesasMoedaNacional"
                  class="form-control"
                  v-model="model.valorTotalDespesasMoedaNacional"
                  v-bind="money"
                ></money>
                <span
                  v-show="errors.has('valorTotalDespesasMoedaNacional')"
                  class="parsley-errors-list filled"
                >Valor em Real (R$) é um campo obrigatório.</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group bd-t-0-force bd-r-0-force ht-60-force">
                <label class="form-control-label"> Taxa Câmbio </label>
                <money
                  name="valorTotalDespesasMoedaNacionalTaxaConversao"
                  class="form-control"
                  v-model="model.valorTotalDespesasMoedaNacionalTaxaConversao"
                  v-bind="taxa"
                  disabled
                ></money>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group bd-t-0-force bd-r-0-force ht-60-force">
                <label class="form-control-label">
                  Mesma Moeda Condição de Venda nas Adições</label
                >
                <div class="input-group-solved tx-normal">
                  <toggle-button
                    :sync="true"
                    :labels="{ checked: 'Sim', unchecked: 'Não' }"
                    :height="18"
                    :width="50"
                    :color="{ unchecked: '#bf0909' }"
                    v-model="model.in_moeda_unica"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="section-title mg-t-0"> Frete Total</label>
          <div class="row no-gutters">
            <div class="col-md-6">
              <div class="form-group ht-60-force bd-r-1">
                <label class="form-control-label"> Collect </label>
                <money
                  name="valorTotalFreteCollect"
                  class="form-control"
                  v-model="model.valorTotalFreteCollect"
                  v-bind="money"
                ></money>
                <span
                  v-show="errors.has('valorTotalFreteCollect')"
                  class="parsley-errors-list filled"
                >Collect é um campo obrigatório.</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ht-60-force bd-l-0-force bd-r-1">
                <label class="form-control-label"> Prepaid </label>
                <money
                  name="valorTotalFretePrepaid"
                  class="form-control"
                  v-model="model.valorTotalFretePrepaid"
                  v-bind="money"
                ></money>
                <span
                  v-show="errors.has('valorTotalFretePrepaid')"
                  class="parsley-errors-list filled"
                >Prepaid é um campo obrigatório.</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ht-60-force bd-t-0-force">
                <label class="form-control-label"> Moeda </label>
                <v-select
                  v-model="model.codigoMoedaFrete"
                  label="name"
                  :options="coinsMapComplete"
                  name="codigoMoedaFrete"
                  class="wd-90p"
                  @change="calcularMoedaTotalFrete"
                >
                  <template slot="option" slot-scope="option">
                    <strong>{{ option.code }}</strong>
                    {{ option.name }}
                  </template>
                </v-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ht-60-force bd-t-0-force bd-l-0-force">
                <label class="form-control-label"> Em Território </label>
                <money
                  name="valorTotalFreteMoedaNacional"
                  class="form-control"
                  v-model="model.valorTotalFreteMoedaNacional"
                  v-bind="money"
                ></money>
                <span
                  v-show="errors.has('valorTotalFreteMoedaNacional')"
                  class="parsley-errors-list filled"
                >Em Território Nacional é um campo obrigatório.</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ht-60-force bd-t-0-force bd-r-1">
                <label class="form-control-label"> Valor em Real (R$) </label>
                <money
                  name="valorTotalFreteTotalEmReal"
                  class="form-control"
                  v-model="model.valorTotalFreteTotalEmReal"
                  v-bind="money"
                ></money>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ht-60-force bd-t-0-force bd-l-0-force">
                <label class="form-control-label"> Taxa Câmbio </label>
                <money
                  name="valorTotalFreteTaxaConversao"
                  class="form-control"
                  v-model="model.valorTotalFreteTaxaConversao"
                  v-bind="taxa"
                  disabled
                ></money>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="section-title mg-t-0"> Seguro Total </label>
          <div class="row no-gutters">
            <div class="col-md-6">
              <div class="form-group bd-r-0-force ht-60-force bd-l-0-force">
                <label class="form-control-label"> Valor na Moeda </label>
                <money
                  name="valorTotalSeguroMoedaNegociada"
                  class="form-control"
                  v-model="model.valorTotalSeguroMoedaNegociada"
                  v-bind="money"
                ></money>
                <span
                  v-show="errors.has('valorTotalSeguroMoedaNegociada')"
                  class="parsley-errors-list filled"
                >Valor na Moeda é um campo obrigatório.</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ht-60-force bd-r-0-force">
                <label class="form-control-label"> Moeda </label>
                <v-select
                  v-model="model.codigoMoedaSeguro"
                  label="name"
                  :options="coinsMapComplete"
                  @change="calcularMoedaTotalSeguro"
                  name="codigoMoedaSeguro"
                  class="wd-90p"
                >
                  <template slot="option" slot-scope="option">
                    <strong>{{ option.code }}</strong>
                    {{ option.name }}
                  </template>
                </v-select>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="form-group ht-60-force bd-t-0-force bd-r-0-force bd-l-0-force"
              >
                <label class="form-control-label"> Valor em Real (R$) </label>
                <money
                  name="valorTotalSeguroMoedaNacional"
                  class="form-control"
                  v-model="model.valorTotalSeguroMoedaNacional"
                  v-bind="money"
                ></money>
                <span
                  v-show="errors.has('valorTotalSeguroMoedaNacional')"
                  class="parsley-errors-list filled"
                >Valor em Real (R$) é um campo obrigatório.</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ht-60-force bd-t-0-force bd-r-0-force">
                <label class="form-control-label"> Taxa Câmbio </label>
                <money
                  name="valorTotalSeguroMoedaNacionalTaxaConversao"
                  class="form-control"
                  v-model="model.valorTotalSeguroMoedaNacionalTaxaConversao"
                  v-bind="taxa"
                  disabled
                ></money>
              </div>
            </div>
            <div class="col-md-12">
              <!-- <div class="form-group ht-60-force bd-t-0-force bd-t-0-force bd-l-0-force bd-r-0-force bd-b-0-force pd-0-force" v-if="model.adicoes !== undefined && model.adicoes !== null && model.adicoes.length > 0">
<template v-if="this.model.numeroRegistro == null || this.model.numeroRegistro == undefined">
    <a href="#" class="btn btn-success mg-t-10 mg-b-6 mg-l-10" @click="calcularRateiosDI()">
                                            <i class="icon fa fa-refresh tx-22"></i> CALCULAR RATEIOS DA DI
                                        </a>
</template>
                        </div> -->
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="section-title mg-t-0">
            Acréscimos
            <a
              href="#"
              class="mg-t-10 mg-b-6 mg-l-10 tx-uppercase"
              @click="calcularAcrescimos()"
            >
              <i class="icon fa fa-refresh tx-22"></i> CALCULAR ACRÉSCIMOS
            </a>
          </label>
          <div class="row no-gutters">
            <div class="col-md-6">
              <div class="form-group bd-r-0-force ht-60-force">
                <label class="form-control-label"> Valor na Moeda </label>
                <money
                  name="valorTotalAcrescimosMoedaNegociada"
                  class="form-control"
                  v-model="model.valorTotalAcrescimosMoedaNegociada"
                  v-bind="money"
                ></money>
                <span
                  v-show="errors.has('valorTotalAcrescimosMoedaNegociada')"
                  class="parsley-errors-list filled"
                >Valor na Moeda é um campo obrigatório.</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ht-60-force">
                <label class="form-control-label"> Moeda </label>
                <v-select
                  v-model="model.codigoMoedaAcrescimo"
                  label="name"
                  :options="coinsMapComplete"
                  name="codigoMoedaAcrescimo"
                  class="wd-90p"
                  @change="calcularMoedaTotalAcrescimos"
                >
                  <template slot="option" slot-scope="option">
                    <strong>{{ option.code }}</strong>
                    {{ option.name }}
                  </template>
                </v-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group bd-t-0-force ht-60-force bd-r-0-force">
                <label class="form-control-label"> Valor em Real (R$) </label>
                <money
                  name="valorTotalAcrescimosMoedaNacional"
                  class="form-control"
                  v-model="model.valorTotalAcrescimosMoedaNacional"
                  v-bind="money"
                ></money>
                <span
                  v-show="errors.has('valorTotalAcrescimosMoedaNacional')"
                  class="parsley-errors-list filled"
                >Valor em Real (R$) é um campo obrigatório.</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group bd-t-0-force ht-60-force">
                <label class="form-control-label"> Taxa Câmbio </label>
                <money
                  name="valorTotalAcrescimosMoedaNacionalTaxaConversao"
                  class="form-control"
                  v-model="model.valorTotalAcrescimosMoedaNacionalTaxaConversao"
                  v-bind="taxa"
                  disabled
                ></money>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8">
          <div class="report-summary-header mg-t-20 mg-b-10">
            <div>
              <h4 class="tx-inverse mg-b-3">
                Acréscimos p/ Rateio nas Adições
              </h4>
              <p class="mg-b-0">
                <i class="icon ion-information-circled mg-r-3"></i>
                Lista de acréscimos para rateio nas adições cadastradas na DI.
              </p>
            </div>
            <div>
              <template
                v-if="
                  this.model.numeroRegistro == null ||
                  this.model.numeroRegistro == undefined
                "
              >
                <a
                  href="#"
                  class="btn btn-primary"
                  @click.prevent="criarRateio()"
                  v-if="
                    model.adicoes !== undefined &&
                    model.adicoes !== null &&
                    model.adicoes.length > 0
                  "
                >
                  <i class="icon fa fa-save tx-22"></i> RATEAR ACRÉSCIMOS NAS
                  ADIÇÕES
                </a>
                <a
                  href="#"
                  class="btn btn-danger"
                  @click.prevent="removerRateios()"
                  v-if="
                    model.adicoes !== undefined &&
                    model.adicoes !== null &&
                    model.adicoes.length > 0
                  "
                >
                  <i class="icon ion-trash-a tx-22"></i> REMOVER
                </a>
              </template>
              <!--
                        <a href="#" class="btn btn-danger">
                            <i class="icon ion-trash-a tx-22"></i> REMOVER ACRÉSCIMOS
                        </a> -->
            </div>
          </div>
          <table class="table mg-b-0 bd">
            <thead class="thead-colored bg-teal">
            <tr>
              <th class="tx-center">Denominação</th>
              <th class="tx-center">Moeda</th>
              <th class="tx-center">Valor na Moeda</th>
              <th class="tx-center">Valor em Reais</th>
              <th class="tx-center">Ações</th>
            </tr>
            </thead>
            <tbody>
            <template
              v-if="
                  model.acrescimos !== null &&
                  model.acrescimos !== undefined &&
                  model.acrescimos.length > 0
                "
            >
              <tr-inline
                v-for="item in model.acrescimos"
                :key="item.id"
                :row="item"
                @save="updateAcrescimos"
              >
                <td-inline
                  placeholder="Código"
                  v-model="item.codigoMetodoAcrescimoValor"
                  type="select"
                  selectType="key|value"
                  :options="codigosMetodoAcrescimos"
                />
                <td-inline
                  placeholder="Moeda"
                  v-model="item.codigoMoedaNegociadaAcrescimo"
                  type="select"
                  :options="[...coinsMap]"
                />
                <td-inline
                  type="money"
                  v-model="item.valorAcrescimoMoedaNegociada"
                  @change="updateTaxaCambioAcrescimo(item)"
                ></td-inline>
                <td-inline
                  type="money"
                  v-model="item.valorAcrescimoMoedaNacional"
                ></td-inline>

                <template slot="actions">
                  <a
                    href="#"
                    @click.prevent="removeAcrescimos(item)"
                    class="btn btn-danger btn-icon rounded-circle"
                  >
                    <div><i class="icon ion-trash-a"></i></div>
                  </a>
                </template>
              </tr-inline>
            </template>

            <template
              v-if="
                  this.model.numeroRegistro == null ||
                  this.model.numeroRegistro == undefined
                "
            >
              <tr-inline
                class="new-item"
                :isNew="true"
                :row="newAcrescimo"
                @save="createAcrescimos"
              >
                <td-inline
                  placeholder="Código"
                  v-model="newAcrescimo.codigoMetodoAcrescimoValor"
                  type="select"
                  selectType="key|value"
                  :options="codigosMetodoAcrescimos"
                />
                <td-inline
                  placeholder="Moeda"
                  v-model="newAcrescimo.codigoMoedaNegociadaAcrescimo"
                  type="select"
                  :options="[...coinsMap]"
                />
                <td-inline
                  type="money"
                  v-model="newAcrescimo.valorAcrescimoMoedaNegociada"
                  @change="updateTaxaCambioAcrescimo(newAcrescimo)"
                ></td-inline>
                <td-inline
                  type="money"
                  v-model="newAcrescimo.valorAcrescimoMoedaNacional"
                ></td-inline>
              </tr-inline>
            </template>
            </tbody>
          </table>
        </div>

        <div class="col-md-4">
          <div class="report-summary-header mg-t-20 mg-b-10">
            <div>
              <h4 class="tx-inverse mg-b-3">
                Declaração de Exportação Estrangeira
              </h4>
              <p class="mg-b-0">
                <i class="icon ion-information-circled mg-r-3"></i>
                Declaração de exportação estrangeira p/ países do mercosul.
              </p>
            </div>
            <div></div>
          </div>
          <table class="table mg-b-0 bd">
            <thead class="thead-colored bg-teal">
            <tr>
              <th class="tx-center">Número</th>
              <th class="tx-center">Início</th>
              <th class="tx-center">Fim</th>
              <th class="tx-center">Ações</th>
            </tr>
            </thead>
            <tbody>
            <template
              v-if="
                  model.informacaoMercosul !== null &&
                  model.informacaoMercosul !== undefined &&
                  model.informacaoMercosul.length > 0
                "
            >
              <tr-inline
                v-for="item in model.informacaoMercosul"
                :key="item.id"
                :row="item"
                @save="updateInformacaoMercosul"
              >
                <td-inline
                  placeholder="Número da DE"
                  type="string"
                  v-model="item.numeroDEMercosul"
                />
                <td-inline
                  placeholder="Faixa de Início"
                  type="string"
                  v-model="item.numeroREInicial"
                />
                <td-inline
                  placeholder="Faixa de Fim"
                  type="string"
                  v-model="item.numeroREFinal"
                />

                <template slot="actions">
                  <a
                    href="#"
                    @click.prevent="removeInformacaoMercosul(item)"
                    class="btn btn-danger btn-icon rounded-circle"
                  >
                    <div><i class="icon ion-trash-a"></i></div>
                  </a>
                </template>
              </tr-inline>
            </template>

            <template
              v-if="
                  this.model.numeroRegistro == null ||
                  this.model.numeroRegistro == undefined
                "
            >
              <tr-inline
                class="new-item"
                :isNew="true"
                :row="newInformacaoMercosul"
                @save="createInformacaoMercosul"
              >
                <td-inline
                  placeholder="Número DE"
                  type="string"
                  v-model="newInformacaoMercosul.numeroDEMercosul"
                />
                <td-inline
                  placeholder="Faixa Início"
                  type="string"
                  v-model="newInformacaoMercosul.numeroREInicial"
                />
                <td-inline
                  placeholder="Faixa Fim"
                  type="string"
                  v-model="newInformacaoMercosul.numeroREFinal"
                />
              </tr-inline>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";
import {TheMask} from "vue-the-mask";
import DatePicker from "vue2-datepicker";

import vSelect from "vue-select";
import {Money} from "v-money";
import {mapState} from "vuex";

import {EventBus} from "@/events/EventBus.js";

import * as importDeclarationLists from "@/lists/import-declaration-lists.js";
import inlineRow from "@/components/InlineRow.vue";
import InlineCell from "@/components/InlineCell.vue";
import Swal from "sweetalert2";
import ToggleButton from "vue-js-toggle-button/src/Button.vue";

export default {
  name: "di-form-carga",
  props: ["noClick"],
  components: {
    "v-select": vSelect,
    TheMask,
    DatePicker,
    Money,
    "tr-inline": inlineRow,
    "td-inline": InlineCell,
    ToggleButton,
  },
  data() {
    return {
      model: {},
      initialModel: {},
      newEmbalagem: {
        codigoTipoEmbalagemCarga: "",
        quantidadeVolumeCarga: "",
      },
      newArmazem: {
        nomeArmazemCarga: "",
      },
      newAcrescimo: {
        codigoMetodoAcrescimoValor: "",
        codigoMoedaNegociadaAcrescimo: "",
        valorAcrescimoMoedaNacional: 0.0,
        valorAcrescimoMoedaNegociada: 0.0,
      },
      newInformacaoMercosul: {
        numeroDEMercosul: "",
        numeroREFinal: "",
        numeroREInicial: "",
      },
      codigosTipoProcesso: [...importDeclarationLists.codigosTipoProcesso],
      codigosTipoEmbalagens: [...importDeclarationLists.codigosTipoEmbalagens],
      codigosPaises: [...importDeclarationLists.codigosPaises],
      codigosMetodoAcrescimos: [
        ...importDeclarationLists.codigosMetodoAcrescimos,
      ],
      codigosURFs: [],
      listOfRecintosAlfandegados: [],
      listOfSetoreArmazenamento: [],
      kg: {
        decimal: ",",
        thousands: ".",
        precision: 5,
        masked: false,
        suffix: " KGs",
      },
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      taxa: {
        decimal: ",",
        thousands: ".",
        precision: 5,
        masked: false,
      },
    };
  },
  methods: {
    async updateTaxaCambioAcrescimo(item, target) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        if (
          item.codigoMoedaNegociadaAcrescimo !== undefined &&
          item.codigoMoedaNegociadaAcrescimo !== null &&
          item.codigoMoedaNegociadaAcrescimo !== "" &&
          item.valorAcrescimoMoedaNegociada !== null
        ) {
          const codigo = item.codigoMoedaNegociadaAcrescimo;
          const data = new Date();

          const response = await this.$store.dispatch(
            "MyIMPStore/consultarTaxaCambio",
            {
              codigoMoeda: codigo,
              date: this.$moment(data).format("Y-MM-DD"),
              auto: true,
            }
          );

          if (response != null) {
            const value =
              item.valorAcrescimoMoedaNegociada * response.taxaConversao;
            item.valorAcrescimoMoedaNacional = value;
          }
        }
      }
    },
    onSubmit() {
      return true;
    },
    async getCodigoURFs(search) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        const response = await this.$store.dispatch(
          "MyIMPStore/consultarCodigoURF",
          search
        );
        if (!response)
          Swal.fire(
            "Consultar URF",
            `Não foi possível encontrar URFs cadastradas com o Código <b>${search}</b>.`,
            "error"
          );
      }
    },
    async getCodigoRecintos(search) {
      if (
        ((this.model.numeroRegistro == null ||
            this.model.numeroRegistro == undefined) &&
          this.model !== undefined &&
          this.model.codigoUrfDespacho === null) ||
        this.model.codigoUrfDespacho === undefined ||
        this.model.codigoUrfDespacho === ""
      ) {
        Swal.fire(
          "URF de Despacho",
          "A URF de despachado ainda não foi preenchida.",
          "error"
        );
      } else if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        const codigoURF = this.model.codigoUrfDespacho.codigo;

        const response = await this.$store.dispatch(
          "MyIMPStore/consultarRecintos",
          {
            codigoURF,
            codigoRecinto: search,
          }
        );
        if (response) {
          this.listOfRecintosAlfandegados = response;
          this.$set(this, "listOfRecintosAlfandegados", response);
        } else {
          Swal.fire(
            "Consultar Recinto",
            `Não foi possível encontrar recintos cadastrados com o termo <b>${search}</b>.`,
            "error"
          );
        }
      }
    },
    async getCodigosSetoreArmazenamentos(event) {
      if (
        ((this.model.numeroRegistro == null ||
            this.model.numeroRegistro == undefined) &&
          this.model !== undefined &&
          this.model.codigoUrfDespacho === null) ||
        this.model.codigoUrfDespacho === undefined ||
        this.model.codigoUrfDespacho === ""
      ) {
        Swal.fire(
          "URF de Despacho",
          "A URF de despachado ainda não foi preenchida.",
          "error"
        );
      } else if (
        (this.model !== undefined &&
          this.model.codigoRecintoAlfandegado === null) ||
        this.model.codigoRecintoAlfandegado === ""
      ) {
        Swal.fire(
          "Recinto Alfandegado",
          "O recinto alfandegado ainda não foi selecionado.",
          "error"
        );
      } else if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        const codigoURF = this.model.codigoUrfDespacho.codigo;
        const codigoRecinto = this.model.codigoRecintoAlfandegado.codigo;

        const response = await this.$store.dispatch(
          "MyIMPStore/consultarListaSetores",
          {
            codigoURF,
            recAduaneiro: codigoRecinto,
          }
        );

        if (response) {
          this.listOfSetoreArmazenamento = response;
          this.$set(this, "listOfSetoreArmazenamento", response);
        }
      }
    },
    async calcularMoedaLocalEmbarque() {
      if (
        (this.model.numeroRegistro == null ||
          this.model.numeroRegistro == undefined) &&
        this.model.codigoMoedaLocalEmbarque !== undefined &&
        this.model.codigoMoedaLocalEmbarque !== null
      ) {
        const codigo = this.model.codigoMoedaLocalEmbarque.code;
        const data = new Date();

        const response = await this.$store.dispatch(
          "MyIMPStore/consultarTaxaCambio",
          {
            codigoMoeda: codigo,
            date: this.$moment(data).format("Y-MM-DD"),
            auto: true,
          }
        );

        if (response != null) {
          const value =
            this.model.valorTotalDespesasMoedaNegociada *
            response.taxaConversao;
          this.model.valorTotalDespesasMoedaNacional = value;
          this.model.valorTotalDespesasMoedaNacionalTaxaConversao =
            response.taxaConversao;
          this.$set(this.model, "valorTotalDespesasMoedaNacional", value);
          this.$set(
            this.model,
            "valorTotalDespesasMoedaNacionalTaxaConversao",
            response.taxaConversao
          );

          const res = await this.$store.dispatch("MyIMPStore/patch", {
            diff: this.model,
            newModel: this.model,
            fieldName: null,
          });
        }
      }
    },
    async calcularMoedaTotalSeguro() {
      if (
        (this.model.numeroRegistro == null ||
          this.model.numeroRegistro == undefined) &&
        this.model.codigoMoedaSeguro !== undefined &&
        this.model.codigoMoedaSeguro !== null
      ) {
        const codigo = this.model.codigoMoedaSeguro.code;
        const data = new Date();

        const response = await this.$store.dispatch(
          "MyIMPStore/consultarTaxaCambio",
          {
            codigoMoeda: codigo,
            date: this.$moment(data).format("Y-MM-DD"),
            auto: true,
          }
        );

        if (response != null) {
          const value =
            this.model.valorTotalSeguroMoedaNegociada * response.taxaConversao;
          this.model.valorTotalSeguroMoedaNacional = value;
          this.model.valorTotalSeguroMoedaNacionalTaxaConversao =
            response.taxaConversao;
          this.$set(this.model, "valorTotalSeguroMoedaNacional", value);

          const res = await this.$store.dispatch("MyIMPStore/patch", {
            diff: this.model,
            newModel: this.model,
            fieldName: null,
          });
        }
      }
    },
    async calcularMoedaTotalAcrescimos() {
      if (
        (this.model.numeroRegistro == null ||
          this.model.numeroRegistro == undefined) &&
        this.model.codigoMoedaAcrescimo !== undefined &&
        this.model.codigoMoedaAcrescimo !== null
      ) {
        const codigo = this.model.codigoMoedaAcrescimo.code;
        const data = new Date();

        const response = await this.$store.dispatch(
          "MyIMPStore/consultarTaxaCambio",
          {
            codigoMoeda: codigo,
            date: this.$moment(data).format("Y-MM-DD"),
            auto: true,
          }
        );

        if (response != null) {
          const value =
            this.model.valorTotalAcrescimosMoedaNegociada *
            response.taxaConversao;
          this.model.valorTotalAcrescimosMoedaNacional = value;
          this.model.valorTotalAcrescimosMoedaNacionalTaxaConversao =
            response.taxaConversao;
          this.$set(this.model, "valorTotalAcrescimosMoedaNacional", value);

          const res = await this.$store.dispatch("MyIMPStore/patch", {
            diff: this.model,
            newModel: this.model,
            fieldName: null,
          });
        }
      }
    },
    async calcularMoedaTotalFrete() {
      if (
        (this.model.numeroRegistro == null ||
          this.model.numeroRegistro == undefined) &&
        this.model.codigoMoedaFrete !== undefined &&
        this.model.codigoMoedaFrete !== null
      ) {
        const codigo = this.model.codigoMoedaFrete.code;
        const data = new Date();

        const response = await this.$store.dispatch(
          "MyIMPStore/consultarTaxaCambio",
          {
            codigoMoeda: codigo,
            date: this.$moment(data).format("Y-MM-DD"),
            auto: true,
          }
        );

        if (response != null) {
          let value = 0;

          if (this.model.valorTotalFreteCollect !== null)
            value += this.model.valorTotalFreteCollect * response.taxaConversao;
          if (this.model.valorTotalFretePrepaid !== null)
            value += this.model.valorTotalFretePrepaid * response.taxaConversao;
          if (this.model.valorTotalFreteMoedaNacional !== null)
            value -=
              this.model.valorTotalFreteMoedaNacional * response.taxaConversao;

          this.model.valorTotalFreteTotalEmReal = value;
          this.model.valorTotalFreteTaxaConversao = response.taxaConversao;
          this.$set(this.model, "valorTotalFreteTotalEmReal", value);

          const res = await this.$store.dispatch("MyIMPStore/patch", {
            diff: this.model,
            newModel: this.model,
            fieldName: null,
          });

          if (res) {
            this.initialModel = this.model;
          }
        }
      }
    },
    async updateAcrescimos(value) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        this.submitAcrescimos();
      }
    },
    async createAcrescimos(value) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        this.model.acrescimos.push(value);
        this.submitAcrescimos();
      }
    },
    async removeAcrescimos(value) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        Swal.fire({
          title: "Excluir Acréscimo",
          html: `Você realmente deseja excluir o acréscimo <strong>${value.codigoMetodoAcrescimoValor.value}</strong>? Se você excluir, precisará adicioná-lo novamente na DI.`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.value) {
            const index = this.model.acrescimos.findIndex(
              (item) =>
                item.codigoMetodoAcrescimoValor ===
                value.codigoMetodoAcrescimoValor
            );

            if (index !== "" && index !== null) {
              this.model.acrescimos.splice(index, 1);
              this.$store.commit("MyIMPStore/SET_ACTIVE", this.model);
              // this.submitEmbalagem();
              this.calcularAcrescimos();
              Swal.fire(
                "Excluir Acréscimo",
                ` O acréscimo <strong>${value.codigoMetodoAcrescimoValor.value}</strong> foi excluído com sucesso da lista de acréscimos.`,
                "success"
              );
            } else {
              Swal.fire(
                "Excluir Acréscimo",
                ` O acréscimo <strong>${value.codigoMetodoAcrescimoValor.value}</strong> não pode ser excluído da lista de acréscimos.`,
                "error"
              );
            }
          }
        });
      }
    },
    async submitAcrescimos() {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        const response = await this.$store.dispatch("MyIMPStore/patch", {
          diff: {
            acrescimos: this.model.acrescimos,
          },
          newModel: this.model,
          fieldName: "acrescimos",
        });

        this.calcularAcrescimos();

        if (response) {
          Swal.fire(
            "Declaração de Importação",
            "O acréscimo foi salvo com sucesso na DI.",
            "success"
          );
          this.newAcrescimo = {
            codigoMetodoAcrescimoValor: "",
            codigoMoedaNegociadaAcrescimo: "",
            valorAcrescimoMoedaNacional: 0.0,
            valorAcrescimoMoedaNegociada: 0.0,
          };
        }
      }
    },
    async updateEmbalagem(value) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        this.submitEmbalagem();
      }
    },
    async createEmbalagem(value) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        this.model.embalagem.push(value);
        this.submitEmbalagem();
      }
    },
    async removeEmbalagem(value) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        Swal.fire({
          title: "Excluir Embalagem",
          html: "Você realmente deseja excluir o volume? Se você excluir, precisará adicioná-lo novamente na DI.",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.value) {
            const index = this.model.embalagem.findIndex(
              (item) =>
                item.codigoTipoEmbalagemCarga === value.codigoTipoEmbalagemCarga
            );

            if (index !== "" && index !== null) {
              this.model.embalagem.splice(index, 1);
              this.$store.commit("MyIMPStore/SET_ACTIVE", this.model);
              this.submitEmbalagem();
              Swal.fire(
                "Excluir Volume",
                " O volume foi excluído com sucesso da lista de volumes.",
                "success"
              );
            } else {
              Swal.fire(
                "Excluir Volume",
                " O volume não pode ser excluído da lista de volumes.",
                "error"
              );
            }
          }
        });
      }
    },
    async submitEmbalagem() {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        const response = await this.$store.dispatch("MyIMPStore/patch", {
          diff: {
            embalagem: this.model.embalagem,
          },
          newModel: this.model,
          fieldName: "embalagem",
        });

        if (response) {
          Swal.fire(
            "Declaração de Importação",
            "O volume foi salvo com sucesso na DI.",
            "success"
          );
          this.newEmbalagem = {
            codigoTipoEmbalagemCarga: "",
            quantidadeVolumeCarga: "",
          };
        }
      }
    },
    async updateArmazem(value) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        this.submitArmazem();
      }
    },
    async createArmazem(value) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        this.model.armazem.push(value);
        this.submitArmazem();
      }
    },
    async removeArmazem(value) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        Swal.fire({
          title: "Excluir Armazém",
          html: `Você realmente deseja excluir o Armazém <strong>${value.nomeArmazemCarga}</strong>? Se você excluir, precisará adicioná-lo novamente na DI.`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.value) {
            const index = this.model.armazem.findIndex(
              (item) => item.nomeArmazemCarga === value.nomeArmazemCarga
            );
            if (index !== "" && index !== null) {
              this.model.armazem.splice(index, 1);
              this.$store.commit("MyIMPStore/SET_ACTIVE", this.model);
              this.submitEmbalagem();
              Swal.fire(
                "Excluir Armazém",
                `O armazém <strong>${value.nomeArmazemCarga}</strong> foi excluído com sucesso.`,
                "success"
              );
            } else {
              Swal.fire(
                "Excluir Armazém",
                `O armazém <strong>${value.nomeArmazemCarga}</strong> não foi excluído.`,
                "error"
              );
            }
          }
        });
      }
    },
    async submitArmazem() {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        const response = await this.$store.dispatch("MyIMPStore/patch", {
          diff: {
            armazem: this.model.armazem,
          },
          newModel: this.model,
          fieldName: "armazem",
        });

        if (response) {
          Swal.fire(
            "Declaração de Importação",
            "O armazém do recinto foi salvo com sucesso na DI.",
            "success"
          );
          this.newArmazem = {
            nomeArmazemCarga: "",
          };
        }
      }
    },
    async updateInformacaoMercosul(value) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        this.submitInformacaoMercosul();
      }
    },
    async createInformacaoMercosul(value) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        this.model.informacaoMercosul.push(value);
        this.submitInformacaoMercosul();
      }
    },
    async removeInformacaoMercosul(value) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        Swal.fire({
          title: "Excluir Declaração de Exportação Estrangeira",
          html: `Você realmente deseja excluir a declaração <strong>${value.numeroDEMercosul}</strong>? Se você excluir, precisará adicioná-lo novamente na DI.`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.value) {
            const index = this.model.informacaoMercosul.findIndex(
              (item) => item.numeroDEMercosul === value.numeroDEMercosul
            );
            if (index !== "" && index !== null) {
              this.model.informacaoMercosul.splice(index, 1);
              this.$store.commit("MyIMPStore/SET_ACTIVE", this.model);
              this.submitInformacaoMercosul();
              Swal.fire(
                "Excluir Declaração de Exportação Estrangeira",
                `A declaração <strong>${value.numeroDEMercosul}</strong> foi excluída com sucesso da lista de declarações de exportação estrangeiras.`,
                "success"
              );
            } else {
              Swal.fire(
                "Excluir Declaração de Exportação Estrangeira",
                `A declaração <strong>${value.numeroDEMercosul}</strong> não foi excluído da lista de declarações de exportação estrangeiras.`,
                "error"
              );
            }
          }
        });
      }
    },
    async submitInformacaoMercosul() {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        const response = await this.$store.dispatch("MyIMPStore/patch", {
          diff: {
            informacaoMercosul: this.model.informacaoMercosul,
          },
          newModel: this.model,
          fieldName: "informacaoMercosul",
        });

        if (response) {
          Swal.fire(
            "Declaração de Exportação",
            "A declaração de exportação estrangeira foi salva com sucesso.",
            "success"
          );
          this.newInformacaoMercosul = {
            numeroDEMercosul: "",
            numeroREInicial: "",
            numeroREFinal: "",
          };
        }
      }
    },
    async loadData() {
      if (
        this.model.numeroRegistro == null ||
        (this.model.numeroRegistro == undefined && !this.coins.length > 0)
      ) {
        const coins = await Axios.get(`${config.env.API_DEFAULT.host}/coins`);
        this.$store.commit(
          "ProcessStore/SET_COINS",
          coins.data._embedded.coins
        );
      }
    },
    async diffObjects(fieldName) {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        const response = await this.$store.dispatch("MyIMPStore/diffObjects", {
          initialModel: this.initialModel,
          model: this.model,
          fieldName,
        });

        if (response) {
          this.initialModel = {
            ...this.model,
          };
        }
      }
    },
    async calcularRateiosDI() {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        await this.$store.dispatch("MyIMPStore/calcularRateiosDI", {
          id: this.model.id,
          activeAdicao: null,
        });
      }
    },
    criarRateio() {
      const self = this;
      if (
        this.model.numeroRegistro == null ||
        (this.model.numeroRegistro == undefined && his.validarPesoLiquido())
      ) {
        Swal.fire({
          title: "Rateio de Acréscimos",
          html: "Você realmente deseja ratear os acréscimos nas adições? Os acréscimos atuais das adições serão removidos e substituídos pelos novos valores. ",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, ratear!",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.value) {
            this.model.adicoes.forEach((adicao) => {
              if (adicao.acrescimo.length > 0) {
                adicao.acrescimo = [];
              }

              const {cargaPesoLiquido} = this.model;
              const {pesoLiquidoMercadoria} = adicao;

              if (cargaPesoLiquido <= 0) {
                Swal.fire(
                  "Rateio de Acréscimos",
                  "O Peso Liquído da Carga precisa ser maior do que 0.",
                  "error"
                );
              } else if (!pesoLiquidoMercadoria > 0) {
                Swal.fire(
                  "Rateio de Acréscimos",
                  `O Peso Liquído da Adição ${adicao.numeroAdicao} precisa ser maior do que 0.`,
                  "error"
                );
              } else if (pesoLiquidoMercadoria > cargaPesoLiquido) {
                Swal.fire(
                  "Rateio de Acréscimos",
                  `O Peso Liquído da Adição ${adicao.numeroAdicao} é maior do que o peso da Carga.`,
                  "error"
                );
              } else {
                this.model.acrescimos.forEach((acrescimo) => {
                  if (acrescimo.valorAcrescimoMoedaNegociada <= 0) {
                    Swal.fire(
                      "Rateio de Acréscimos",
                      `O acréscimo ${acrescimo.codigoMetodoAcrescimoValor} precisa ter o valor na moeda negociada maior do que 0,00.`,
                      "error"
                    );
                    return;
                  }

                  if (acrescimo.valorAcrescimoMoedaNacional <= 0) {
                    Swal.fire(
                      "Rateio de Acréscimos",
                      `O acréscimo ${acrescimo.valorAcrescimoMoedaNacional} precisa ter o valor na moeda nacional maior do que 0,00.`,
                      "error"
                    );
                    return;
                  }

                  const valorAcrescimoMoedaNacional =
                    (acrescimo.valorAcrescimoMoedaNacional / cargaPesoLiquido) *
                    pesoLiquidoMercadoria;
                  const valorAcrescimoMoedaNegociada =
                    (acrescimo.valorAcrescimoMoedaNegociada /
                      cargaPesoLiquido) *
                    pesoLiquidoMercadoria;

                  adicao.acrescimo.push({
                    codigoMetodoAcrescimoValor:
                    acrescimo.codigoMetodoAcrescimoValor,
                    codigoMoedaNegociadaAcrescimo:
                    acrescimo.codigoMoedaNegociadaAcrescimo,
                    valorAcrescimoMoedaNacional,
                    valorAcrescimoMoedaNegociada,
                  });

                  if (
                    adicao.acrescimo.length === this.model.acrescimos.length
                  ) {
                    Swal.fire(
                      "Rateio de Acréscimos",
                      "Rateio de acréscimos realizado com sucesso para todas as adições que foram encontradas nesta DI.",
                      "success"
                    );
                  }
                });

                this.$store.commit("MyIMPStore/SET_ACTIVE", this.model);
                this.$store.dispatch("MyIMPStore/patch", {
                  diff: this.model,
                  newModel: this.model,
                  fieldName: null,
                });
              }
            });
          }
        });
      }
    },

    removerRateios() {
      Swal.fire({
        title: "Excluir Acréscimo",
        html: "Você realmente deseja excluir todos os Acréscimos desta DI? Se você excluir, precisará adicioná-los novamente na DI.",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (this.model.acrescimos.length > 0) {
          this.model.acrescimos = [];
        }

        if (this.model.adicoes.length > 0) {
          this.model.adicoes.forEach((adicao) => {
            adicao.acrescimo = [];
          });
        }

        this.$store.dispatch("MyIMPStore/patch", {
          diff: this.model,
          newModel: this.model,
          fieldName: null,
        });
      });
    },
    validarPesoLiquido() {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        const qtdAdicoes = this.model.adicoes.length;

        let mensagemErro =
          "<p class='mg-b-5'>Não foi possível criar o rateio dos acréscimos da DI nas adições: </p>";
        mensagemErro += "<ul class='list-group list-group-striped tx-14'>";

        let erro = false;
        if (this.model.cargaPesoLiquido <= 0) {
          mensagemErro +=
            "<li class='list-group-item'>" +
            "   <p class='mg-b-0'>" +
            "       <i class='fa fa-check tx-danger mg-r-8'></i> " +
            "       <span class='tx-inverse tx-normal'>" +
            "Peso Líquido da DI não foi preenchido" +
            "       </span>";
          "   </p>" + "</li>";
          erro = true;
        } else if (qtdAdicoes <= 0) {
          mensagemErro +=
            "<li class='list-group-item'>" +
            "   <p class='mg-b-0'>" +
            "       <i class='fa fa-check tx-danger mg-r-8'></i> " +
            "       <span class='tx-inverse tx-normal'>" +
            "Não existem adições cadastradas" +
            "       </span>";
          "   </p>" + "</li>";
          erro = true;
        }

        let pesoLiquidoPreenchido = true;
        this.model.adicoes.forEach((item) => {
          if (
            item.pesoLiquidoMercadoria === 0 ||
            item.pesoLiquidoMercadoria === "" ||
            item.pesoLiquidoMercadoria === null
          ) {
            pesoLiquidoPreenchido = false;
          }
        });

        if (!pesoLiquidoPreenchido) {
          mensagemErro +=
            "<li class='list-group-item'>" +
            "   <p class='mg-b-0'>" +
            "       <i class='fa fa-check tx-danger mg-r-8'></i> " +
            "       <span class='tx-inverse tx-normal'>" +
            "           Peso Líquido de uma ou mais adições não foi preenchido" +
            "       </span>";
          "   </p>" + "</li>";
          erro = true;
        }

        mensagemErro += "</ul>";
        mensagemErro = `<div class='tx-left'>${mensagemErro}</div>`;

        if (erro) {
          Swal.fire("Criar Rateio de Acréscimos", mensagemErro, "error");
          return false;
        }
        return true;
      }
    },
    async gerarCalculos() {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        this.calcularMoedaLocalEmbarque()
          .then((response) => this.calcularMoedaTotalSeguro())
          .then((response) => this.calcularMoedaTotalFrete())
          .then((response) => this.calcularMoedaTotalAcrescimos())
          .then((response) => this.calcularRateiosDI())
          .then((response) =>
            this.$store.dispatch("MyIMPStore/calcularValorAduaneiro", {
              id: this.model.id,
              activeAdicao: null,
            })
          )
          .then((response) => {
            if (this.model.adicoes.length > 0) {
              this.model.adicoes.forEach((adicao) => {
                this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", adicao);
                this.$store.commit("MyIMPStore/SET_ACTIVE_ADICAO", null);
                EventBus.$emit(
                  "operational.import-declaration.adition.Form.Tributos.gerarImpostos"
                );
              });
            }
          });
      }
    },
    calcularAcrescimos() {
      if (
        this.model.numeroRegistro == null ||
        this.model.numeroRegistro == undefined
      ) {
        if (
          this.model.acrescimos !== null &&
          this.model.acrescimos.length > 0
        ) {
          let totalAcrescimos = 0;
          this.model.acrescimos.forEach((a) => {
            totalAcrescimos += a.valorAcrescimoMoedaNacional;

            // valorTotalAcrescimosMoedaNegociada
            // valorTotalAcrescimosMoedaNacional
            // codigoMoedaAcrescimo
          });

          totalAcrescimos = parseFloat(totalAcrescimos).toFixed(2);

          if (
            this.model.codigoMoedaLocalEmbarque !== null &&
            this.model.valorTotalDespesasMoedaNacionalTaxaConversao !== null &&
            this.model.valorTotalAcrescimosMoedaNacionalTaxaConversao !== null
          ) {
            this.model.codigoMoedaAcrescimo =
              this.model.codigoMoedaLocalEmbarque;
            this.model.valorTotalAcrescimosMoedaNacionalTaxaConversao =
              this.model.valorTotalDespesasMoedaNacionalTaxaConversao;
            this.model.valorTotalAcrescimosMoedaNegociada =
              totalAcrescimos /
              this.model.valorTotalAcrescimosMoedaNacionalTaxaConversao;
          } else {
            Swal.fire(
              "Cacular Acréscimos",
              "Você precisar preencher os campos de Valor das Mercadorias no Local de Embarque.",
              "error"
            );
          }
        }
      }
    },
  },
  computed: {
    ...mapState({
      listOfURFs: (state) => state.MyIMPStore.listOfURFs,
      coins: (state) => state.ProcessStore.coins,
      activeDI: (state) => state.MyIMPStore.activeDI,
    }),
    options() {
      return [
        "blur",
        (e) => {
          let fieldName = null;
          if (e.path !== undefined && e.path[0] !== undefined) {
            fieldName = e.path[0].name;
          }
          setTimeout(() => {
            this.diffObjects(fieldName);
          }, 200);
        },
        true,
      ];
    },
    coinsMapComplete() {
      return this.coins.map((item) => ({
        name: `${item.code} - ${item.swiftCode}`,
        swiftCode: item.swiftCode,
        code: item.code,
      }));
    },
    coinsMap() {
      return this.coins.map((item) => ({
        label: `${item.code} - (${item.swiftCode}) ${item.name}`,
        value: item.code,
      }));
    },
  },
  mounted() {
    this.loadData();

    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById("di-form-carga");
        form.addEventListener(...this.options);
      }, 200);
    }

    this.model = {
      ...this.activeDI,
    };

    this.initialModel = {
      ...this.activeDI,
    };

    EventBus.$on(
      "operational.import-declaration.Form.Carga.gerarCalculos",
      () => {
        if (
          this.model.numeroRegistro == null ||
          this.model.numeroRegistro == undefined
        ) {
          return this.gerarCalculos();
        }
      }
    );
    EventBus.$on(
      "operational.import-declaration.Form.Carga.criarRateio",
      () => {
        if (
          this.model.numeroRegistro == null ||
          this.model.numeroRegistro == undefined
        ) {
          return this.criarRateio();
        }
      }
    );
  },
  beforeDestroy() {
  },
  watch: {
    activeDI() {
      // this.initialModel = {
      //     ...this.activeDI
      // };
      this.model = {
        ...this.activeDI,
      };
    },
    "model.valorTotalDespesasMoedaNegociada": function (newVal, oldVal) {
      this.calcularMoedaLocalEmbarque();
    },
    "model.valorTotalSeguroMoedaNegociada": function (newVal, oldVal) {
      this.calcularMoedaTotalSeguro();
    },
    "model.valorTotalAcrescimosMoedaNegociada": function (newVal, oldVal) {
      this.calcularMoedaTotalAcrescimos();
    },
    "model.valorTotalFreteCollect": function (newVal, oldVal) {
      this.calcularMoedaTotalFrete();
    },
    "model.valorTotalFretePrepaid": function (newVal, oldVal) {
      this.calcularMoedaTotalFrete();
    },
    "model.valorTotalFreteMoedaNacional": function (newVal, oldVal) {
      this.calcularMoedaTotalFrete();
    },
  },
};
</script>

<style>
.swal2-popup {
  width: 600px !important;
}

#botaoLixo {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}

#botaoAdd {
  display: inline-table !important;
  padding: 5px 15px !important;
  border-radius: 100% !important;
}
</style>
