<template>
  <div>
    <div class="registration-title">
      <h2 class="text-dark text-thin">{{ this.modelTitle }}</h2>
      <div>
        <button class="crm-button" @click.prevent="openCompanyModal" title="Adicionar Empresa">
          <div class="flex ai-c fgap1">
            <plus-icon :size="20" color="currentColor" /> Adicionar
          </div>
        </button>
      </div>
    </div>
    
    <hr class="mb-4" />

    <TableSSR 
      @pageChange="getDataList($event.page, $event.perPage, $event.search)"
      @searchChange="getDataList(1, $event.perPage, $event.search)"
      @perPageChange="getDataList($event.page, $event.perPage, $event.search)"
      :columns="tableColumns" 
      :current-page="tableData?.number + 1 || tableData.number + 1"
      :last-page="tableData.totalPages"
      :tableData="tableData.content"
      :totalRecords="tableData.totalElements"
      :table-config="{
        useSearch: true,
        centeredCell: false,
        showRecords: true,
        paginationTop: true,
        typeSearch: 'query',
        showPerPageTop: true,
        showPerPageBottom: true,
        perPage: tableData?.size ?? 10,
      }"
    >
      <template #enabled="{ item }">
        <div class="text-center" v-if="'enabled' in item">
          <span
            class="config-table-tag text-center"
            :class="{ success: item.enabled, danger: !item.enabled }"
          >
            {{ item.enabled ? "Sim" : "Não" }}
          </span>
        </div>
      </template>
      <template #name="{ item }">
        <div v-if="'name' in item">
          <p v-if="item.name" class="flex fd-c"> 
            <span class="text-color-primary">{{ item.name }}</span>
            <small v-if="item.completeName && !ignoreColumns?.includes('completeName')">{{ item.completeName }}</small>
          </p>
          <p v-else> - </p>
        </div>
      </template>
      <template #completeName="{ item }">
        <div v-if="'completeName' in item">
          <p v-if="item.completeName"> 
            {{ item.completeName }}
          </p>
          <p v-else> - </p>
        </div>
      </template>
      <template #federalID="{ item }">
        <div v-if="'federalID' in item" style="min-width: 155px">
          <p v-if="item.federalID"> 
            {{ item.federalID | formatarCNPJ }}
          </p>
          <p v-else> - </p>
        </div>
      </template>
      <template #telephone1="{ item }">
        <div v-if="'telephone1' in item" style="min-width: 130px">
          <p v-if="item.telephone1"> 
            {{ item.telephone1 | formatarTelefone }}
          </p>
          <p v-else> - </p>
        </div>
      </template>
      <template #email="{ item }">
        <div v-if="'email' in item">
          <p v-if="item.email"> 
            {{ formatEmails(item.email) }}
          </p>
          <p v-else> - </p>
        </div>
      </template>
      <!-- Column Actions -->
      <template #actions="{ item }">
        <div class="flex ai-fe jc-fe gap1">
          <a class="edit-button" href="#" @click.prevent="getCompany(item.id)" title="Editar">
            <EditIcon :size="16" />
          </a>
          <a v-if="showContacts" class="info-button" href="#" @click.prevent="getContacts(item.id, item.name)" title="Contatos">
            <UsersIcon :size="16" />
          </a>
        </div>
      </template>
      <!-- Column Actions -->
    </TableSSR>

    <VModal
      :handler="companyAddModal"
      @requestClose="() => closeCompanyModal()"
      :title="companyData.id ? 'Atualizar Cadastro' : 'Adicionar'"
      description=""
      :width="1600"
      :height="0"
    >
      <template #content>
        <form @submit.prevent class="registration-form">

          <div class="span-12 registration-form">
            <div class="span-12 flex ai-c jc-sb fw-w">
              <h4 class="text-dark mb-0">Tipo de Relacionamento <span class="tx-danger">*</span></h4>
              <p style="margin-bottom: 0">Os campos marcados com <span class="tx-danger">*</span> são obrigatórios.</p>
            </div>
            <div class="span-12">
              <div class="flex ai-gc gap1 fw-w">
                <v-checkbox name="isCustomBroker" label="Despachante Aduaneiro" v-model="companyData.customBroker"></v-checkbox>
                <v-checkbox name="isConveyor" label="Transportadora" v-model="companyData.conveyor"></v-checkbox>
                <v-checkbox name="isForwarder" label="Freight Forwarder" v-model="companyData.forwarder"></v-checkbox>
                <v-checkbox name="isCommissioned" label="Comissionado" v-model="companyData.isCommissioned"></v-checkbox>
                <v-checkbox name="shipowner" label="Armador" v-model="companyData.shipowner"></v-checkbox>
                <v-checkbox name="isExporter" label="Exportador / Fabricante" v-model="companyData.exporter"></v-checkbox>
                <v-checkbox name="isInsuranceCompany" label="Seguradora" v-model="companyData.insuranceCompany"></v-checkbox>
              </div>
              <div class="field-error" v-if="getError('typeRelation')">
                {{ getError("typeRelation") }}
              </div>
            </div>
          </div>

          <div class="span-12">
            <hr class="mt-0 mb-0">
          </div>

          <div class="span-12 registration-form">
            <div class="span-12">
              <h4 class="text-dark mb-0">Dados Gerais</h4>
            </div>

            <div class="span-12">
              <label>Status</label>
              <v-switch
                name="enabled"
                :labels="{
                  checked: `Habilitado`,
                  unchecked: `Desabilitado`,
                }"
                v-model="companyData.enabled"
              />
              <div class="field-error" v-if="getError('enabled')">
                {{ getError("enabled") }}
              </div>
            </div>

            <div class="span-3" v-if="showFederalID">
              <div>
                <v-input
                  type="text"
                  name="federalID"
                  label="CNPJ"
                  v-model="companyData.federalID"
                  :mask="['##.###.###/####-##']"
                  :class="{'input-danger': getError('federalID') }"
                ></v-input>
                <div class="field-error" v-if="getError('federalID')">
                  {{ getError("federalID") }}
                </div>
              </div>
            </div>

            <div class="span-9" v-if="showFederalID">
              <button
                class="btn btn-oblong bg-black-8 tx-white tx-bold btn-block wd-200 tx-uppercase"
                @click.prevent="newFetchDataFromCNPJ"
              >
                Consultar CNPJ
              </button>
            </div>

            <div class="span-3">
              <v-input
                type="text"
                name="name"
                label="Nome Fantasia"
                :mask="[]"
                :requiredFlag="true"
                v-model="companyData.name"
                :class="{'input-danger': getError('name') }"
                @blur="validateNameFind(companyData?.id ? companyData.id : null)"
              ></v-input>
              <div class="field-error" v-if="getError('name')">
                {{ getError("name") }}
              </div>
            </div>

            <div class="span-3" v-if="showCompleteName">
              <v-input
                type="text"
                name="completeName"
                label="Razão Social"
                v-model="companyData.completeName"
              ></v-input>
              <div class="field-error" v-if="getError('completeName')">
                {{ getError("completeName") }}
              </div>
            </div>

            <div class="span-3">
              <v-input
                type="email"
                name="email"
                label="E-mail"
                v-model="companyData.email"
              ></v-input>
              <div class="field-error" v-if="getError('email')">
                {{ getError("email") }}
              </div>
            </div>

            <div class="span-3">
              <v-input
                type="text"
                name="telephone1"
                label="Telefone"
                v-model="companyData.telephone1"
                :mask="['(##) ####-####', '(##) #####-####']"
              ></v-input>
            </div>

            <div class="span-3" v-if="showTraderIdentificationNumber">
              <v-input
                type="text"
                name="traderIdentificationNumber"
                label="TIN (Trader Identification Number)"
                v-model="companyData.traderIdentificationNumber"
                maxlength="35"
              ></v-input>
            </div>

            <div class="span-9" v-if="showTypeFreight">
              <label for="typeFreight">Quais tipos de frente o agente fornece?</label>
              <div class="flex ai-gc">
                <label class="ckbox mb-0">
                  <input type="checkbox" id="isFCLAgent" name="isFCLAgent" v-model="companyData.isFCLAgent"><span>FCL</span>
                </label>
              
                <label class="ckbox mb-0">
                  <input type="checkbox" id="isLCLAgent" name="isLCLAgent" v-model="companyData.isLCLAgent"><span>LCL</span>
                </label>

                <label class="ckbox mb-0">
                  <input type="checkbox" id="isAirAgent" name="isAirAgent" v-model="companyData.isAirAgent"><span>Aéreo</span>
                </label>

                <label class="ckbox mb-0">
                  <input type="checkbox" id="isTerrestrialAgent" name="isTerrestrialAgent" v-model="companyData.isTerrestrialAgent"><span>Rodoviário</span>
                </label>
              </div>
              <div class="field-error" v-if="getError('typeFreight')">
                {{ getError("typeFreight") }}
              </div>
            </div>
          </div>

          <div class="span-12">
            <hr class="mt-0 mb-0">
          </div>
          
          <div class="span-12 registration-form">
            <div class="span-12">
              <h4 class="text-dark mb-0">Informações de Endereço</h4>
            </div>

            <div class="span-2">
              <v-input
                type="text"
                name="postalCode"
                label="CEP"
                v-model="companyData.postalCode"
                :mask="['#####-###']"
                @blur="findCEP"
              ></v-input>
            </div>

            <div class="span-4">
              <v-input
                type="text"
                name="address"
                label="Logradouro"
                v-model="companyData.address"
              ></v-input>
            </div>

            <div class="span-2">
              <v-input
                type="number"
                name="addressNumber"
                min="0"
                label="Número"
                v-model="companyData.addressNumber"
              ></v-input>
            </div>

            <div class="span-4">
              <v-input
                type="text"
                name="complement"
                label="Complemento"
                v-model="companyData.complement"
              ></v-input>
            </div>

            <div class="span-3">
              <v-input
                type="text"
                name="district"
                label="Bairro"
                v-model="companyData.district"
              ></v-input>
            </div>

            <div class="span-3">
              <v-input
                type="text"
                name="city"
                label="Cidade"
                v-model="companyData.city"
              ></v-input>
            </div>

            <div class="span-3">
              <select-wrapper
                v-model="companyData.federalUnity"
                :options="ufs"
                label="UF"
              ></select-wrapper>
            </div>

            <div class="span-3" v-if="showCountry">
               <select-wrapper
                v-model="companyData.country"
                :options="codigosPaises"
                label="País"
              ></select-wrapper>
            </div>

          </div>

          <div class="span-12">
            <hr class="mt-0 mb-0">
          </div>

          <div class="span-12 registration-form">
            <div class="span-12">
              <h4 class="text-dark mb-0">Informações de Integração</h4>
            </div>

            <div class="span-6">
              <v-input
                type="text"
                name="code"
                label="Código de Integração (Código Rudolph dos Exportadores)"
                v-model="companyData.code"
              ></v-input>
            </div>
          </div>

        </form>
      </template>

      <template #footer>
        <div class="flex ai-c jc-c fgap2">
          <button class="crm-button red" @click.prevent="() => closeCompanyModal()" >
            Cancelar
          </button>
          <button class="crm-button" @click.prevent="saveCompany(false)" >
            Salvar
          </button>
          <button class="crm-button add-contact" v-if="!companyData?.id && showContacts"  @click.prevent="saveCompany(true)">
            Salvar e Adicionar Contato
          </button>
        </div>
      </template>
    </VModal>

    <RegistrationsListContacts 
      @closeListContactsCompanyModal="closeListContactsCompanyModal" 
      :company="company"
      :companyName="companyNameContactsModal" 
      :listContactsCompanyModal="companyListContactsModal" 
      :companyID="companyIDContactsModal" 
    />

  </div>
</template>

<script>
import * as labelsRegistrationsOperational from "@/labels/labels.registrations.operational.js";
import * as importDeclarationLists from '@/lists/import-declaration-lists.js';
import RegistrationsListContacts from "./RegistrationsListContacts.vue";
import SelectWrapper from "@/components/SelectWrapper.vue";
import UsersIcon from "@/components/icons/UsersIcon.vue";
import EditIcon from "@/components/icons/EditIcon.vue";
import PlusIcon from "@/components/crm/Icons/Plus.vue";
import VCheckbox from '@/components/VCheckbox.vue';
import TableSSR from "@/components/TableSSR.vue";
import VSwitch from "@/components/VSwitch.vue";
import VModal from "@/components/VModal.vue";
import VInput from "@/components/VInput.vue";
import * as constants from "@/consts";
import Swal from "sweetalert2";
import config from "@/config";
import * as yup from "yup";
import Axios from "axios";

export default {
  name: 'RegistrationsList',
  components : {
    PlusIcon,
    TableSSR,
    EditIcon,
    UsersIcon,
    VModal,
    VInput,
    VSwitch,
    SelectWrapper,
    RegistrationsListContacts,
    VCheckbox,
  },
  props: {
    company: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      modelTitle: '',
      companyName: '',
      companyAddModal: false,
      companyListContactsModal: false,
      companyNameContactsModal: '',
      companyIDContactsModal: 0,
      refreshModal: false,
      showContacts: false,
      ignoreColumns: [],
      formErrors: [],
      tableData: [],
      tableColumns: [],
      contacts: [],
      filterDefauts: {},
      companyData: {
        name: "",
        completeName: "",
        enabled: true,
        federalID: "",
        email: "",
        telephone1: "",
        postalCode: "",
        address: "",
        addressNumber: "",
        complement: "",
        district: "",
        city: "",
        federalUnity: "",
        country: "",
        customBroker: false,
        conveyor: false,
        forwarder: false,
        isCommissioned: false,
        shipowner: false,
        exporter: false,
        isFCLAgent: false, 
        isLCLAgent: false,  
        isAirAgent: false, 
        isTerrestrialAgent: false, 
        insuranceCompany: false,
        code: "",
      },
    };
  },
  methods: {
    getError(field) {
      if (this.formErrors.length > 0) {
        const item = this.formErrors.find((e) => e.path === field || e.type === field);
        if (item && item.message) {
          return this.formErrors.find((e) => e.path === field || e.type === field).message;
        }
      } else {
        return null;
      }
    },

    formatEmails(emails) {
      return emails.replace(/,/g, ', ')
    },

    async findCEP() {
      if(this.companyData.postalCode.length < 9) return
      try {
        this.$store.commit("setLoading", {
          show: true,
          label: "Consulta de CEP",
          description: "Carregando...",
        });

        const response = await this.$viaCep.buscarCep(this.companyData.postalCode);

        if (response.erro) {
          Swal.fire({
            title: "Consultar CEP",
            html: "<p>Não foi possível encontrar este endereço, verifique se o CEP é válido</p>",
            type: "error",
          });
        } else {
          this.companyData = {
            ...this.companyData,
            address: response.logradouro,
            district: response.bairro,
            city: response.localidade,
            complement: response.complemento,
            federalUnity: response.uf,
          };
        }
      } catch (e) {
        console.log(e);
        Swal.fire({
          title: "Consultar CEP",
          html: "<p>Não foi possível encontrar este endereço, verifique se o CEP é válido</p>",
          type: "error",
        });
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },

    async newFetchDataFromCNPJ() {
      if(this.companyData.federalID.length < 14) return

      this.$store.commit("setLoading", {
        show: true,
        label: "Consulta de CNPJ",
        description: "Carregando...",
      });

      try {
        const response = await Axios.get(
          `https://publica.cnpj.ws/cnpj/${this.companyData.federalID.replace(
            /[^\w\s]/gi,
            ""
          )}`
        );

        if (response.status === 200) {
          const { data } = response;
          const { estabelecimento } = data;

          this.companyData.completeName = data.razao_social;
          this.companyData.name = estabelecimento.nome_fantasia;
          this.companyData.telephone1 = estabelecimento.ddd1 + estabelecimento.telefone1;

          this.companyData.address = estabelecimento.logradouro;
          this.companyData.postalCode = estabelecimento.cep;
          this.companyData.addressNumber = estabelecimento.numero;
          this.companyData.federalUnity = estabelecimento.estado.nome;
          this.companyData.city = estabelecimento.cidade.nome;
          this.companyData.district = estabelecimento.bairro;

          if (estabelecimento.complemento) {
            this.companyData.complement = estabelecimento.complemento;
          }
        }
      } catch (e) {
        console.log(e);
        Swal.fire({
          title: "Consultar CNPJ",
          html: "<p>Não foi possível encontrar este CNPJ, verifique se é válido</p>",
          type: "error",
        });
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    changeType() {
      if (this.company !== null && labelsRegistrationsOperational.labels[this.company] !== undefined) {
        const { title, columns, name, filters, showContacts, ignoreColumns} = labelsRegistrationsOperational.labels[this.company];
        this.modelTitle = title;
        this.tableColumns = columns;
        this.companyName = name;
        this.filterDefauts = filters;
        this.showContacts = showContacts;
        this.ignoreColumns = ignoreColumns;
      }
    },

    async getDataList(page = 1, perPage = 10, search = "") {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando listagem de registros.",
      });

      try {
        const params = {
          page: page - 1,
          search: search.toLowerCase(),
          size: perPage,
        };

        if (this.filterDefauts) {
          this.filterDefauts.forEach((filter) => {
            params[filter.field] = filter.value;
          });
        }

        const response = await Axios.get(`${config.env.API_DEFAULT.host}/company/findAllByFiltersPaginate`, { params });
        this.tableData = response.data.data;
      } catch (e) {
        console.log(e);
        Swal.fire({
          title: "Cadastros Operacionais",
          text: "Erro ao listar os registros!",
          type: "error",
        });
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },

    openCompanyModal() {
      
      if (this.company === 'despachantes-aduaneiros') {
        this.companyData.customBroker = true;
      } else if (this.company === 'transportadoras') {
        this.companyData.conveyor = true;
      } else if (this.company === 'freight-forwarders') {
        this.companyData.forwarder = true;
      } else if (this.company === 'comissionados') {
        this.companyData.isCommissioned = true;
      } else if (this.company === 'armadores') {  
        this.companyData.shipowner = true;
      } else if (this.company === 'exportadores') {
        this.companyData.exporter = true;
      } else if (this.company === 'seguradoras') {
        this.companyData.insuranceCompany = true;
      }

      this.companyAddModal = true;
    },

    async closeCompanyModal() {
      this.companyAddModal = false;
      this.companyData = {
        name: "",
        completeName: "",
        enabled: true,
        federalID: "",
        email: "",
        telephone1: "",
        postalCode: "",
        address: "",
        addressNumber: "",
        complement: "",
        district: "",
        city: "",
        country: "",
        federalUnity: "",
        customBroker: false,
        conveyor: false,
        forwarder: false,
        isCommissioned: false,
        shipowner: false,
        exporter: false,
        isFCLAgent: false, 
        isLCLAgent: false,  
        isAirAgent: false, 
        isTerrestrialAgent: false,
        insuranceCompany: false,
        code: "",
      };

      this.formErrors = [];

      if (this.refreshModal) {
        this.refreshModal = false;
        await this.getDataList();
      }
    },

    closeListContactsCompanyModal() {
      this.companyNameContactsModal = '';
      this.companyIDContactsModal = 0;
      this.companyListContactsModal = false;
    },

    async saveCompany(addContact) {
      try {

        this.formErrors = [];
        let response = {};

        this.$store.commit("setLoading", {
          show: true,
          label: this.modelTitle,
          description: "Salvando, aguarde...",
        });

        let schema = this.schema();
        await schema.validate(this.companyData, {
          abortEarly: false,
        });

        if (this.companyData.federalID) {
          let validateCNPJFind = await this.validateCNPJFind(this.companyData?.id);
          if (validateCNPJFind) {
            Swal.fire({
              title: "CNPJ já cadastrado!",
              html: validateCNPJFind,
              type: "error",
            });

            this.$nextTick(() => {
              const links = document.querySelectorAll(".view-profile");
              links.forEach(link => {
                link.addEventListener("click", (event) => {
                  event.preventDefault();
                  this.closeCompanyModal();
                  Swal.close();
                  const companyId = link.getAttribute("data-id");
                  this.getCompany(companyId);
                });
              });
            });
            return;
          }
        }

        if (this.companyData?.id) {
          response = await Axios.put(`${config.env.API_DEFAULT.host}/companies/${this.companyData.id}`, {
            ...this.companyData,
          });
        } else {
          let account = this.$store.state.credentials.data?.account?.id ?? 1;
          response = await Axios.post(`${config.env.API_DEFAULT.host}/companies`, {
            ...this.companyData,
            account: `${config.env.API_DEFAULT.host}/accounts/${account}`,
          });
        }

        Swal.fire({
          title: this.companyName,
          text: "Salvo com sucesso!",
          type: "success",
        });

        this.refreshModal = true;
        await this.closeCompanyModal();
        await this.getDataList();
        
        if (addContact) {
          await this.getContacts(response.data.id, response.data.name)
        }

      } catch (e) {
        // console.log(e);
        if (e.name && e.name === "ValidationError") {
          this.formErrors = e.inner;
        } else {
          
          let message = "Erro ao salvar!";
          if (e?.response?.data?.message) {
            message = e.response.data.message;
          }

          Swal.fire({
            title: this.companyName,
            text: message,
            type: "error",
          });
        }
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },

    async getCompany(id) {
      this.$store.commit("setLoading", {
        show: true,
        description: "Carregando Dados...",
      });

      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/companies/${id}`);
        this.companyData = response.data;
        this.companyAddModal = true;
      } catch (e) {
        console.log(e.data);
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },

    async getContacts(companyId, companyName) {
      this.companyNameContactsModal = companyName;
      this.companyIDContactsModal = companyId;
      this.companyListContactsModal = true;
    },

    schema() {
      const validateFederalID = (value) => {
        if (value !== '') {
          return yup.string().test(
            'federalID',
            'O CNPJ deve ter pelo menos 14 caracteres',
            (value) => value.length >= 18
          ).validate(value);
        } else {
          return true;
        }
      };

      let schema = yup.object().shape({
        name: yup.string()
          .required("O Nome Fantasia é obrigatório!")
          .min(3, "O Nome Fantasia deve conter no mínimo 3 caracteres!"),
        federalID: yup.string()
        .transform(value => (value === null || value === undefined ? '' : value)) 
        .test(
          'federalID',
          'O CNPJ deve ter pelo menos 14 caracteres',
          validateFederalID
        ),
      }).test('typeRelation', 'Selecione pelo menos um tipo de relacionamento', function (values) {
        const { customBroker, conveyor, forwarder, isCommissioned, shipowner, exporter, insuranceCompany  } = values;
        return customBroker || conveyor || forwarder || isCommissioned || shipowner || exporter || insuranceCompany;
      });
      
      return schema;
    },
    async validateCNPJFind(companyId = null) {

      let federalIDWithMask = this.companyData.federalID;
      let federalIDWithoutMask = this.companyData.federalID.replace(/[.\-\/]/g, '');

      const existsResponse = await Axios.get(
        `${config.env.API_DEFAULT.host}/companies/search/existsByFederalIDWithOrWithoutMask?federalIDWithMask=${federalIDWithMask}&federalIDWithoutMask=${federalIDWithoutMask}`
      );

      if (companyId) {
        existsResponse.data._embedded.companies = existsResponse.data._embedded.companies.filter(c => c.id !== companyId);
      }

      let htmlError = "";

      if (existsResponse.data._embedded.companies.length > 0) {

        let textCompaniesLength = "um empresa";
        if (existsResponse.data._embedded.companies.length > 1) {
          textCompaniesLength = "algumas empresas cadastradas";
        }

        htmlError = "<div>";
        htmlError += `<h4 class="mb-4 text-left">Já existe ${textCompaniesLength} cadastrada com esse CNPJ. Acesse o perfil abaixo e adicione <strong>"${this.companyName}"</strong> no campo <strong>"Tipo de Relacionamento"</strong>.</h4>`;

        htmlError += '<ul class="list-group">';
        for ( let i = 0; i < existsResponse.data._embedded.companies.length; i++) {
          const c = existsResponse.data._embedded.companies[i];
          htmlError += `
            <li class="list-group-item flex ai-c jc-sb fgap2">
              <p class="mg-b-0">${c.name} (${c.enabled ? "Habilitado" : "Desabilitado"})</p>
              <a href="#" data-id="${c.id}" rel="nofollow noopener noreferrer" class="crm-button view-profile small">Ver Pefil</a>
            </li>
          `;
        }
        htmlError += "</ul>";

        htmlError += "</div>";
      }

      return htmlError;
    },

    async validateNameFind(companyId = null) {
      try {
        if (this.companyData.name == null || this.companyData.name == "") {
          return "";
        }

        this.$store.commit("setLoading", {
          show: true,
          label: 'Verificando Nome Fantasia',
          description: "Consultando, aguarde...",
        });

        const existsResponse = await Axios.get(
          `${config.env.API_DEFAULT.host}/company/validateNameSimilarity?name=${this.companyData.name}`
        );

        if (companyId && existsResponse.data.data.length > 0) {
          existsResponse.data.data = existsResponse.data.data.filter(c => c.id !== companyId);
        }

        let htmlError = "";

        if (existsResponse.data.data.length > 0) {

          htmlError = "<div>";
          htmlError += `<p class="mb-4 text-justify">Já existem empresas cadastradas com esse 'Nome Fantasia' ou similar. Verifique se é uma das empresas abaixo e, se for o caso, acesso o perfil e adicione <strong>'${this.companyName}'</strong> no campo <strong>'Tipo de Relacionamento'</strong>.`;

          htmlError += '<ul class="list-group">';
          for ( let i = 0; i < existsResponse.data.data.length; i++) {
            const c = existsResponse.data.data[i];
            htmlError += `
              <li class="list-group-item flex ai-c jc-sb fgap2">
                <p class="mg-b-0">${c.name} (${c.enabled ? "Habilitado" : "Desabilitado"})</p>
                <a href="#" data-id="${c.id}" rel="nofollow noopener noreferrer" class="crm-button view-profile small">Ver Pefil</a>
              </li>
            `;
          }
          htmlError += "</ul>";

          htmlError += "</div>";
        }
        
        if (htmlError == "") {
          return;
        }

        Swal.fire({
          title: "Nome Fantasia!",
          html: htmlError,
          type: "warning",
          customClass: 'alert-validate-name',
          confirmButtonText: "Continuar Cadastro",
        });

        this.$nextTick(() => {
          const links = document.querySelectorAll(".view-profile");
          links.forEach(link => {
            link.addEventListener("click", (event) => {
              event.preventDefault();
              this.closeCompanyModal();
              Swal.close();
              const companyId = link.getAttribute("data-id");
              this.getCompany(companyId);
            });
          });
        });

      } catch (e) {
        console.log(e.data);
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }

      return;
    }
  },
  mounted() {
    this.changeType();
    this.getDataList();
  },
  computed: {
    ufs() {
      return constants.CADASTROS_UF.map((item) => ({
        label: item.nome,
        value: item.sigla,
      }));
    },

     codigosPaises() {
      return importDeclarationLists.codigosPaises.map((item) => ({
        label: item.value,
        value: item.key,
      }));
    },
    showCompleteName() {
      return this.companyData.customBroker 
      || this.companyData.conveyor 
      || this.companyData.forwarder 
      || this.companyData.isCommissioned 
      || this.companyData.insuranceCompany;
    },

    showFederalID() {
      return this.companyData.customBroker 
      || this.companyData.conveyor 
      || this.companyData.forwarder 
      || this.companyData.isCommissioned 
      || this.companyData.insuranceCompany;
    },

    showCountry() {
      return this.companyData.exporter || this.companyData.shipowner;
    },

    showTraderIdentificationNumber() {
      return this.companyData.exporter;
    },

    showTypeFreight() {
      return this.companyData.forwarder;
    },
  },
  filters: {
    formatarCNPJ(cnpj) {
      if (!cnpj) return ''
      cnpj = cnpj.replace(/\D+/g, '')
      if (cnpj.length === 14) {
        return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      } else {
        return cnpj
      }
    },
    formatarTelefone(telefone) {
      if (!telefone) return ''
      telefone = telefone.replace(/\D+/g, '')
      if (telefone.length === 10) {
        return telefone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
      } else if (telefone.length === 11) {
        return telefone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
      } else {
        return telefone
      }
    }
  }
}
</script>

<style scoped>
.registration-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.registration-form {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 15px;
  align-content: start;
}

.registration-form .field-error {
  padding: 0.25rem;
  color: var(--danger);
}

.crm-button.add-contact {
  background-color: #1b84e7;
}
.crm-button.add-contact:hover {
  background-color: #5b93d3;
  color: white;
}
.text-color-primary {
  color: var(--header-text-color-hover);
}
</style>

<style>
.alert-validate-name{
  width: 800px !important;
}
</style>