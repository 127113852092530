<template>
  <div class="registrations-container">
    <RegistrationsSidebar :current="company" :listMenu="changeSideBar()" @changeTabMenuSideBar="(tab) => company = tab"/>

    <div class="registrations-main">
      <RegistrationsList :company="company"/>
    </div>
  </div>
</template>

<script>
import RegistrationsSidebar from './RegistrationsSidebar.vue';
import RegistrationsList from './RegistrationsList.vue';

export default {
  name: 'RegistrationsOperational',
  components: {
    RegistrationsSidebar,
    RegistrationsList,
  },
  data() {
    return { 
      company: this.$route.params.company,
    }
  },
  methods: {
    changeSideBar() {
      return [
        {
          title: "Cadastros Operacionais",
          items: [
            {
              key: "despachantes-aduaneiros",
              title: "Despachantes Aduaneiros",
              subtitle: "Gerencie e visualize os despachantes aduaneiros",
              click: "despachantes-aduaneiros",
              updateUrl: "/crm/cadastros/despachantes-aduaneiros",
              icon: "ListCustomer",
            },
            {
              key: "transportadoras",
              title: "Transportadoras",
              subtitle: "Gerencie e visualize as transportadoras",
              click: "transportadoras",
              updateUrl: "/crm/cadastros/transportadoras",
              icon: "ConveyorBeltIcon",
            },
            {
              key: "freight-forwarders",
              title: "Freight Forwarders ",
              subtitle: "Gerencie e visualize os freight forwarders ",
              click: "freight-forwarders",
              updateUrl: "/crm/cadastros/freight-forwarders",
              icon: "TrolleyIcon",
            },
            {
              key: "comissionados",
              title: "Comissionados",
              subtitle: "Gerencie e visualize os comissionados",
              click: "comissionados",
              updateUrl: "/crm/cadastros/comissionados",
              icon: "UniversalCurrencyIcon",
            },
            {
              key: "armadores",
              title: "Armadores",
              subtitle: "Gerencie e visualize os armadores",
              click: "armadores",
              updateUrl: "/crm/cadastros/armadores",
              icon: "DirectionsBoatIcon",
            },
            {
              key: "exportadores",
              title: "Exportadores / Fabricantes",
              subtitle: "Gerencie e visualize os exportadores / fabricantes",
              click: "exportadores",
              updateUrl: "/crm/cadastros/exportadores",
              icon: "ListCustomer",
            },
            {
              key: "seguradoras",
              title: "Seguradoras",
              subtitle: "Gerencie e visualize os seguradoras",
              click: "seguradoras",
              updateUrl: "/crm/cadastros/seguradoras",
              icon: "VerifiedUserIcon",
            },
          ],
        },
      ]
    }
  },
  watch: {
    company(value) {
      this.$router.replace({ path: '/crm/cadastros/' + value })
    }
  }
}
</script>

<style scoped>
.registrations-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background-color: white;
  height: calc(100vh - 105px);
  overflow: hidden;
}

.registrations-main {
  padding: 1.25rem 0.75rem;
  grid-column: span 9;
  overflow: auto;
}
</style>