<template>
  <div
    :class="[
      'input-control',
      active ? 'active' : '',
      $slots.checkbox ? 'has-checkbox' : '',
      $slots.icon ? 'has-icon' : '',
      dateNoIcon ? 'hide-icon' : '',
      type == 'date' ? 'active' : '',
    ]"
  >
    <label :for="name" class="no-events">
      {{ label }} <span v-if="requiredFlag" class="tx-danger">*</span>
    </label>

    <Money
      v-if="moneyMask"
      v-model.lazy="localValue"
      v-bind="money"
      @blur.native="onBlur($event.target.value)"
      :disabled="disabled"
    ></Money>

    <input
      v-if="mask.length > 0 && type !== 'date'"
      type="tel"
      :name="name"
      :id="name"
      :value="value"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
      @focus="inputFocus"
      @blur="onBlur($event.target.value)"
      v-mask="mask"
      masked="false"
      :disabled="disabled"
      :autocomplete="autocomplete"
    />

    <input
      v-if="mask.length <= 0 && type !== 'date' && !moneyMask"
      :type="type"
      :name="name"
      :id="name"
      :value="value"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
      @focus="inputFocus"
      @blur="onBlur($event.target.value)"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :maxlength="maxlength"
    />

    <date-picker
      v-if="type === 'date'"
      v-model="compValue"
      format="DD/MM/YYYY"
      :value-type="valueType"
      placeholder=""
      class="input-date"
      @focus="inputFocus"
      lang="pt-br"
      @clear="$emit('blur', 'NULL')"
      :disabled="disabled"
    ></date-picker>

    <div class="icon" v-if="$slots.icon">
      <slot name="icon"></slot>
    </div>

    <div class="checkbox" v-if="$slots.checkbox">
      <slot name="checkbox"></slot>
    </div>

    <div class="append" v-if="$slots.append">
      <slot name="append"></slot>
    </div>

    <div v-if="hint.length > 0" :class="['hint']">
      {{ hint }}
    </div>
  </div>
</template>

<script>
import Datepicker from "vue2-datepicker";
import { mask } from "vue-the-mask";
import moment from "moment";
import { Money } from "v-money";

export default {
  name: "VInput",
  directives: {
    mask,
  },
  components: {
    "date-picker": Datepicker,
    Money,
    // Money,
  },
  props: {
    name: {
      type: String,
      required: true,
      default: "input",
    },
    label: {
      type: String,
      required: true,
      default: "label",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    hint: {
      type: String,
      default: "",
    },
    mask: {
      type: Array,
      default: () => [],
    },
    moneyMask: {
      type: Boolean,
      default: false,
    },
    money: {
      type: Object,
      default: () => {
        return {
          decimal: ",",
          thousands: ".",
          precision: 2,
          masked: true,
        }
      }
    },
    value: {},
    valueType: {
      default: "format",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dateNoIcon: {
      type: Boolean,
      default: false,
    },
    requiredFlag: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    maxlength: {
      type: String,
      default: "",
    }
  },
  computed: {
    compValue: {
      get() {
        if (moment(this.value).isValid()) {
          return moment(this.value).format("DD/MM/YYYY");
        }
        return this.value;
      },
      set(value) {
        if (moment(value, "DD/MM/YYYY").isValid()) {
          this.$emit("blur", moment(value, "DD/MM/YYYY").format("YYYY-MM-DD"));
          return this.$emit(
            "input",
            moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")
          );
        }
        return this.$emit("input", null);
      },
    },
    localValue: {
      get() {
        if (this.value) {
          return this.value;
        }
        return "";
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    checkInput(value) {
      if (value) {
        this.active = true;
      } else {
        this.active = false;
      }
      if (this.moneyMask) {
        this.active = true;
      }

      if (this.onBlurFunction) {
        this.onBlurFunction();
      }
    },
    inputFocus() {
      this.active = true;
    },
    emitDataFormatted(value) {
      const dateValue = moment(value).format("YYYY-MM-DD");
      this.$emit("input", dateValue);
    },
    onBlur(e) {
      this.$emit("blur", e);
      this.checkInput(e);
    },
  },
  mounted() {
    this.checkInput(this.value);
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        if (value) {
          this.active = true;
        }
        this.checkInput(this.value);
      },
    },
  },
};
</script>

<style scoped>
.input-control {
  position: relative;
}

input:not([type="checkbox"]) {
  width: 100%;
  height: 45px;
  border: 1px solid #e2e2e2;
  padding: 0px 15px 0 15px;
  outline: none;
  --webkit-ountline: none;
  --moz-outline: none;
  border-radius: 8px;
  background-color: white;
}

input:not([type="checkbox"]):focus {
  background-color: white;
}

label {
  font-size: 0.9rem;
  position: absolute;
  top: 10px;
  left: 15px;
  transition: all 0.2s ease-in-out 0s;
  z-index: 99;
}

label.no-events {
  pointer-events: none;
}

.input-control.active label {
  display: block;
  top: -8px;
  font-size: 0.7rem;
  background-color: white;
  padding: 1px 4px;
  max-width: calc(100% - 30px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-control.has-icon input:not([type="checkbox"]) {
  padding-right: 40px;
}

.input-control.has-icon .icon {
  width: 45px;
  height: 40px;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-control.has-checkbox .checkbox {
  padding-top: 5px;
}

.input-control.has-checkbox .checkbox label {
  position: unset;
  display: flex;
  gap: 10px;
  font-size: 0.8rem;
}

.input-control.has-checkbox .checkbox input {
  display: block;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  border: 1px solid #e2e2e2;
  transition: all 0.2s ease-in-out 0s;
  background: white;
  box-shadow: 0px 0px 0px 2px white inset;
}

.input-control.has-checkbox .checkbox input:checked {
  background: #1b84e7;
  box-shadow: 0px 0px 0px 2px white inset;
}

.input-control .hint {
  transform: translate3d(0, -30px, 0);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s ease-in-out 0s;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  z-index: 99;
  border: 1px solid #e2e2e2;
}

.input-control:hover .hint,
.input-control input:focus ~ .hint {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
  z-index: 102;
}
</style>

<style>
.outside-input .input-control {
  position: relative;
}

.outside-input input:not([type="checkbox"]) {
  width: 100%;
  height: 45px;
  border: 1px solid #e2e2e2;
  padding: 0px 15px 0 15px;
  outline: none;
  --webkit-ountline: none;
  --moz-outline: none;
  border-radius: 8px;
  background-color: white;
}

.outside-input input:not([type="checkbox"]):focus {
  background-color: white;
}

.outside-input label {
  font-size: 0.9rem;
  position: absolute;
  top: 10px;
  left: 15px;
  transition: all 0.2s ease-in-out 0s;
  z-index: 99;
}

.outside-input label.no-events {
  pointer-events: none;
}

.outside-input .input-control.active label {
  display: inline-block;
  top: -8px;
  font-size: 0.7rem;
  background-color: white;
  padding: 1px 4px;
}

.outside-input .input-control.has-icon input:not([type="checkbox"]) {
  padding-right: 60px;
}

.outside-input .input-control.has-icon .icon {
  width: 45px;
  height: 40px;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outside-input .input-control.has-checkbox .checkbox {
  padding-top: 5px;
}

.outside-input .input-control.has-checkbox .checkbox label {
  position: unset;
  display: flex;
  gap: 10px;
  font-size: 0.8rem;
}

.outside-input .input-control.has-checkbox .checkbox input {
  display: block;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  border: 1px solid #e2e2e2;
  transition: all 0.2s ease-in-out 0s;
  background: white;
  box-shadow: 0px 0px 0px 2px white inset;
}

.outside-input .input-control.has-checkbox .checkbox input:checked {
  background: #1b84e7;
  box-shadow: 0px 0px 0px 2px white inset;
}

.outside-input .input-control .hint {
  transform: translate3d(0, -30px, 0);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s ease-in-out 0s;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  z-index: 99;
  border: 1px solid #e2e2e2;
}

.outside-input .input-control:hover .hint,
.outside-input .input-control:focus .hint {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
  z-index: 102;
}

.input-control input:disabled {
  background-color: #f5f6f7;
}

.input-danger input {
  border: 1px solid var(--red) !important;
}

</style>

<style>
.hide-icon .mx-input-append {
  display: none !important;
}
</style>
