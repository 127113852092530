<template>
  <div class="desk-page">
    <desk-state-modal
      v-if="modal.deskState.show"
      @close="closeModalDeskState('close')"
      @finish="closeModalDeskState('finish')"
      :statusFilters="statusFilters"
      :filter="filter"
      :columns="columnsMirror"
    />

    <MainHeader classModify="page-screen-import"/>

    <div class="slim-mainpanel">
      <div class="d-flex justify-content-between align-items-center pd-b-5 pd-t-5 menu-desk" >
        <ul class="nav nav-white-800 tx-white">
          <li
            class="nav-item tx-thin pd-0"
            v-if="filter !== null && filter.selectedTeams.length > 0"
          >
            <a
              href="#"
              class="nav-link pd-r-5 no-pointer"
              title="Equipes Operacionais"
            >
              <span class="pd-r-5 tx-medium">
                <i class="icon ion-person-stalker tx-medium"></i> Equipes:
              </span>
              <span v-for="team in filter.selectedTeams" :key="team.id">
                {{ team.name }},
              </span>
              <i
                class="fa fa-close cursor-pointer"
                @click.prevent="filter.selectedTeams = []"
              ></i>
            </a>
          </li>
          <li
            class="nav-item tx-thin pd-0"
            v-if="
              filter.selectedDateRange.from &&
              filter.selectedDateRange.to &&
              filter.selectedDateField
            "
          >
            <a
              href="#"
              class="nav-link pd-r-5 no-pointer"
              title="Filtro de Datas"
            >
              <span class="pd-r-5 tx-medium">
                <i class="fa fa-calendar tx-medium"></i> Data da(o)
                {{ filter.selectedDateField.label }}:</span
              >
              <span>
                {{ filter.selectedDateRange.from | moment("DD/MM/YYYY") }} até
                {{ filter.selectedDateRange.to | moment("DD/MM/YYYY") }}
                <i
                  class="fa fa-close cursor-pointer"
                  @click.prevent="
                    clearSearchFilterComplete('selectedDateRange')
                  "
                ></i>
              </span>
            </a>
          </li>
          <li
            class="nav-item tx-thin pd-0"
            v-if="filter !== null && filter.selectedCustomsBrokers.length > 0"
          >
            <a
              href="#"
              class="nav-link pd-r-5 no-pointer"
              title="Equipes Operacionais"
            >
              <span class="pd-r-5 tx-medium">
                <i class="icon ion-clipboard tx-medium"></i> Despachantes:
              </span>
              <span
                v-for="customBroker in filter.selectedCustomsBrokers"
                :key="customBroker.id"
              >
                {{ customBroker.name }}
              </span>
              <i
                class="fa fa-close cursor-pointer"
                @click.prevent="filter.selectedCustomsBrokers = []"
              ></i>
            </a>
          </li>
        </ul>
        <ul
          class="nav nav-white-800 tx-white tx-15 align-items-center"
          role="tablist"
        >
          <li class="nav-item">
            <a
              href=""
              class="nav-link pd-r-5"
              title="Filtrar"
              @click.prevent="showFilterDrawer = true"
            >
              <i class="icon ion-funnel tx-bold"></i>
              <span class="tx-thin tx-15 mg-l-5">Filtrar</span>
            </a>
          </li>

          <li class="nav-item">
            <a
              href=""
              class="nav-link pd-r-5"
              title="Salvar"
              @click.prevent="modal.deskState.show = true"
            >
              <i class="icon fa fa-save tx-bold"></i>
              <span class="tx-thin tx-15 mg-l-5 mg-l-5">Salvar</span>
            </a>
          </li>
          <li class="nav-item">
            <div class="dropdown dropdown-a dropleft">
              <a
                href=""
                class="nav-link pd-r-5"
                title="Perfil"
                data-toggle="dropdown"
              >
                <i class="icon ion-person-stalker"></i>
                <span class="tx-thin tx-15 mg-l-5 mg-l-5">Perfil</span>
              </a>

              <div
                class="dropdown-menu dropdown-media-list wd-450"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div class="dropdown-menu-header">
                  <label
                  >Perfis Cadastrados ({{ this.deskStates.length }})</label
                  >
                  <a
                    href="#"
                    class="tx-12"
                    @click.prevent="modal.deskState.show = true"
                  >+ SALVAR ESTADO DA MESA</a
                  >
                </div>

                <div
                  class="list-group list-group-striped list-group-user bd-t-0--force"
                >
                  <template v-if="this.deskStates.length > 0">
                    <div
                      class="list-group-item"
                      :key="'listOfStates-state-' + index"
                      v-for="(state, index) in this.deskStates"
                    >
                      <div
                        class="user-name-address mg-0"
                        @click="selectDeskStates(state)"
                      >
                        <p>{{ state.name }}</p>
                        <span>{{ state.created | moment("DD/MM/YYYY") }}</span>
                      </div>
                      <div class="user-btn-wrapper">
                        <!-- <a href="#" class="btn btn-outline-primary btn-icon">
                            <div class="tx-18"><i class="icon ion-edit"></i></div>
                        </a> -->
                        <a
                          href="#"
                          class="btn btn-outline-danger btn-icon"
                          @click.prevent="deleteDeskStates(state)"
                        >
                          <div class="tx-18">
                            <i class="icon ion-trash-a"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="media-body">
                      <p class="pd-10 tx-center">
                        Nenhum perfil foi cadastrado.
                      </p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </li>

          <!-- <li class="nav-item">
              <a href="" class="nav-link" title="Imprimir" @click.prevent>
                  <i class="icon fa fa-print tx-bold"></i>
                  <span class="tx-thin tx-14 mg-l-5"> Imprimir </span>
              </a>
          </li> -->
          <li class="nav-item">
            <a
              href=""
              class="nav-link pd-r-5"
              title="Configuração de Colunas"
              @click.prevent="showColumnsDrawer = true"
            >
              <i class="icon ion-settings"></i>
              <span class="tx-thin tx-14 mg-l-5">Configuração de Colunas</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              href=""
              class="nav-link pd-r-5"
              title="Legenda"
              @click.prevent="showLegendDrawer = true"
            >
              <i class="icon ion-bookmark tx-bold"></i>
              <span class="tx-thin tx-15 mg-l-5">Legendas</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              href=""
              class="nav-link pd-r-0"
              title="Adicionar"
              @click.prevent="newProcess"
            >
              <i class="icon fa fa-plus-square tx-bold"></i>
              <span class="tx-thin tx-15 mg-l-5">Adicionar</span>
            </a>
          </li>

          <li
            class="tx-20 nav-item search-item nav-link"
          >
            <i class="icon ion-bookmark"></i>
            <form
              @submit.prevent="findProcessByIdentificationSearch()"
              @keyup.escape="findProcessByIdentificationClearSearch()"
            >
              <label for="">
                <input
                  type="number"
                  v-model="findProcessByIdentification"
                  id="findProcessByIdentification"
                  placeholder="Nº Ref"
                  class="wd-75 tx-thin tx-20 tx-center imput-process-by-identification"
                />
                <span
                  v-if="findProcessByIdentification != ''"
                  @click="findProcessByIdentificationClearSearch()"
                >
                  <i class="fa fa-times-circle" aria-hidden="true"></i>
                </span>
              </label>
            </form>
          </li>
        </ul>
      </div>

      <div class="row mg-0 align-items-center">
        
        <div class="col-sm-12 flex justify-content-between align-items-center pd-t-5 pd-b-5">
            <div class="tx-black flex ai-c" style="flex: 0 0 15%">
              <label class="mb-0">Total de <strong>{{ processes.length }}</strong> processos</label>
            </div>
            <div class="flex1 flex ai-c jc-c" style="flex: 0 0 70%">
              <ul class="nav nav-pills status-filters nav-gray-600" role="tablist">
                <li
                  class="nav-item tx-thin"
                  v-for="step in this.steps"
                  :key="step.code"
                >
                  <label class="cursor-pointer mb-0">
                    <div
                      v-if="
                        step !== undefined &&
                        step !== null &&
                        step.code &&
                        step.code != 'todas' && 
                        step.id != 11
                      "
                    >
                      <input
                        type="checkbox"
                        name="filter-status"
                        v-model="statusFilters"
                        :value="step.id"
                      />
                      <template
                        v-if="statusFilters !== null && statusFilters.includes(step.id)"
                      >
                        <div
                          class="nav-link"
                          :class="[{ active: checkActiveStep(step.id) }]"
                          :style="[
                            {
                              borderColor: step.color,
                              backgroundColor: step.color,
                              color: step.textColor,
                            },
                          ]"
                        >
                          <span>{{
                            step.code == "todas" ? "Todas" : step.alias
                          }}</span>
                        </div>
                      </template>
                      <template v-else-if="step.id === 6">
                        <div v-if="step.id === 6" class="nav-link pointer">
                          <span
                            @click.prevent="showCompletedProcessesDrawer = true"
                          >{{ step.alias }}</span
                          >
                        </div>
                      </template>
                      <template v-else>
                        <div class="nav-link">
                          <span>{{
                            step.code == "todas" ? "Todas" : step.alias
                          }}</span>
                        </div>
                      </template>
                    </div>
                    <!-- <div v-if="step.code == 'todas'">
                    <div class="nav-link" @click="statusFilters = []">
                        <span>Todas</span>
                    </div>
                </div> -->
                  </label>
                </li>
              </ul>
            </div>
            <div class="flex ai-c" style="flex: 0 0 15%;">
              <form
                @submit.prevent="searchProcesses('searchFilter')"
                @keyup.escape="clearSearch()"
                class="wd-100p z-index-10"
              >
                <div class="input-group wd-100p z-index-10">
                    <button class="btn bd bg-white tx-gray-600 bd-r-0 tx-thin" type="button" @click.prevent="searchProcesses('searchFilter')">
                      <i class="fa fa-search tx-primary"></i>
                    </button>
                  <input
                    type="text" v-model="searchTerms" id="searchProcesses" placeholder="Pesquisar abaixo..." class="wd-100p form-control"/>
                  <span class="input-group-btn">
                    <button class="btn bd bg-white tx-gray-600 bd-l-0 tx-thin" type="button" v-if="searchTerms != ''" @click="clearSearch()" >
                      <i class="icon ion-trash-a tx-14 "></i>
                    </button>

                  </span>
                </div>
              </form>
            </div>
        </div>

        <div
          class="col-md-12 table-area z-index-100"
          v-show="!showProcess"
          :class="{ showHeader: showHeader, showSearch: showSearch }"
        >
          <processes-table :columns="columns"/>
        </div>
      </div>
    </div>

    <Drawer v-if="showFilterDrawer" @close="showFilterDrawer = false">
      <template slot="header">
        <div>Filtros do Telão Operacional</div>
      </template>
      <template slot="body">
        <div class="mg-y-20 mg-x-10">
          <h6 class="slim-pagetitle">Filtros de Datas</h6>
          <br/>
          <div class="form-layout form-layout-2">
            <div class="row no-gutters">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-control-label">
                    Data Inicial <span class="tx-danger">*</span></label
                  >
                  <date-picker lang="pt-br"
                               v-model="filter.selectedDateRange.from"
                               name="toPickedDate"
                               format="DD/MM/YYYY"
                               placeholder="Data Inicial"
                  ></date-picker>
                </div>
              </div>
              <div class="col-md-6 mg-t--1 mg-md-t-0">
                <div class="form-group mg-md-l--1">
                  <label class="form-control-label">
                    Data Final <span class="tx-danger">*</span></label
                  >
                  <date-picker lang="pt-br"
                               v-model="filter.selectedDateRange.to"
                               name="toPickedDate"
                               format="DD/MM/YYYY"
                               placeholder="Data Final"
                  ></date-picker>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-md-12">
                <div class="form-group bd-t-0-force">
                  <label class="form-control-label"> Campo do Filtro </label>
                  <v-select
                    v-model="filter.selectedDateField"
                    :options="listOfDatesFields"
                    name="label"
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.label }}
                    </template>
                  </v-select>
                </div>
              </div>
            </div>

            <h6 class="slim-pagetitle mg-t-20">Filtros Gerais</h6>
            <br/>
            <div class="row no-gutters">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-control-label"> Equipes </label>
                  <v-select
                    v-model="filter.selectedTeams"
                    label="name"
                    :options="teams"
                    name="team"
                    multiple
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.name + " - " + option.email }}
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-md-12">
                <div class="form-group bd-t-0-force">
                  <label class="form-control-label">
                    Despachante Aduaneiro
                  </label>
                  <v-select
                    v-model="filter.selectedCustomsBrokers"
                    label="name"
                    :options="customsBrokers"
                    name="team"
                    multiple
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                </div>
              </div>
            </div>

            <a
              href="#"
              class="btn btn-primary btn-block mg-t-10"
              @click.prevent="searchProcesses('searchFilterComplete')"
            >
              <i class="icon ion-funnel tx-bold"></i> FILTRAR
            </a>
            <a
              href="#"
              class="btn btn-danger btn-block mg-t-10"
              @click.prevent="
                clearSearchFilterComplete('selectedDateRange');
                clearSearch();
              "
            >
              <i class="icon ion-funnel tx-bold"></i> LIMPAR
            </a>
          </div>
        </div>
      </template>
      <template slot="footer">
        <button
          type="button"
          @click="showFilterDrawer = false"
          class="btn btn-primary"
        >
          Fechar
        </button>
      </template>
    </Drawer>
    <Drawer v-if="showLegendDrawer" @close="showLegendDrawer = false">
      <template slot="header">
        <div>Legenda dos Campos</div>
      </template>
      <template slot="body">
        <div class="mg-y-20 mg-x-10">
          <h6 class="slim-pagetitle">Legendas Disponíveis</h6>
          <br/>
          <div class="table-responsive">
            <table class="table table-striped mg-b-0">
              <thead>
              <tr>
                <th>Campo</th>
                <th>Regra</th>
                <th>Cor</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row">Seguro</th>
                <td>
                  ETA entre 0 <small>(hoje)</small> e 7 dias adiante se o
                  seguro não estiver preenchido.
                </td>
                <th class="bg-red"></th>
              </tr>
              <tr>
                <th scope="row">Seguro</th>
                <td>ETA que já passou do dia de hoje</td>
                <th class="bg-black"></th>
              </tr>
              <tr>
                <th scope="row">Numerário</th>
                <td>
                  ETD menor ou igual o número de dias preenchido no <br/>
                  cadastro do cliente
                  <small>(QUANTIDADE DIAS P/ NOTIFICAÇÃO DO NUMERÁRIO)</small
                  >.
                </td>
                <th class="bg-green"></th>
              </tr>
              <tr>
                <th scope="row">Encerramento Despachante</th>
                <td>Carregamento realizado há 3 dias ou mais.</td>
                <th class="bg-orange"></th>
              </tr>
              <tr>
                <th scope="row">ETA</th>
                <td>
                  ETA maior do que 7 e menor ou igual a 14 dias adiante.
                </td>
                <th class="bg-yellow"></th>
              </tr>
              <tr>
                <th scope="row">ETA</th>
                <td>
                  ETA maior do que 0 <small>(hoje)</small> e menor ou igual a
                  7 dias adiante.
                </td>
                <th class="bg-red"></th>
              </tr>
              <tr>
                <th scope="row">ETA</th>
                <td>ETA igual a 0 dias <small>(hoje)</small>.</td>
                <th class="bg-purple"></th>
              </tr>
              <tr>
                <th scope="row">DI</th>
                <td>Chegada a mais do que 2 dias.</td>
                <th class="bg-orange"></th>
              </tr>
              <tr>
                <th scope="row">Carregamento</th>
                <td>Desembaraço a mais do que 2 dias.</td>
                <th class="bg-orange"></th>
              </tr>
              <tr>
                <th scope="row">Documentos</th>
                <td>ETA menor ou igual a 14 dias adiante.</td>
                <th class="bg-orange"></th>
              </tr>
              <tr>
                <th scope="row">Entrega</th>
                <td>Carregamento menor que 1 dia adiante.</td>
                <th class="bg-orange"></th>
              </tr>
              <tr>
                <th scope="row">DTC / DTA</th>
                <td>
                  Se tiver DTC/DTA e Data de DTC/DTA não estiver preenchida.
                </td>
                <th class="bg-orange"></th>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template slot="footer">
        <button
          type="button"
          @click="showLegendDrawer = false"
          class="btn btn-primary"
        >
          Fechar
        </button>
      </template>
    </Drawer>

    <Drawer v-if="showColumnsDrawer" @close="showColumnsDrawer = false">
      <template slot="header">
        <div>Configuração de Colunas do Telão Operacional</div>
      </template>
      <template slot="body">
        <div class="mg-y-20 mg-x-10 h-100">
          <two-select
            :originalItems="columnsModel"
            :originalSelectedItems="columnsMirror"
            @onChangeSelected="onChangeColumns"
          />
        </div>
      </template>
      <template slot="footer">
        <button
          type="button"
          @click="showColumnsDrawer = false"
          class="btn btn-primary"
        >
          Fechar
        </button>
      </template>
    </Drawer>

    <Drawer
      v-if="showCompletedProcessesDrawer"
      @close="showCompletedProcessesDrawer = false"
    >
      <template slot="header">
        <div>Filtrar processos concluídos</div>
      </template>
      <template slot="body">
        <div class="mg-y-20 mg-x-10">
          <h6 class="slim-pagetitle mt-4 mb-2">Filtrar</h6>
          <span
          >Selecione um campo de dada e um período para filtrar os processos
            concluídos</span
          >
          <br/>
          <div class="form-layout form-layout-2 mt-4">
            <div class="row no-gutters">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-control-label">
                    Selecione um ou mais clientes
                  </label>
                  <v-select
                    v-model="formFilterConcludedProcesses.customersIds"
                    multiple
                    :options="customersActivesOptions"
                    name="label"
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.label }}
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-md-12">
                <div class="form-group bd-t-0-force">
                  <label class="form-control-label">
                    Selecione um campo de data
                  </label>
                  <v-select
                    v-model="formFilterConcludedProcesses.dateField"
                    :options="listOfDatesFields"
                    name="label"
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.label }}
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-md-6">
                <div class="form-group bd-t-0-force">
                  <label class="form-control-label">
                    Data Inicial <span class="tx-danger">*</span></label
                  >
                  <date-picker lang="pt-br"
                               v-model="formFilterConcludedProcesses.startDate"
                               name="toPickedDate"
                               format="DD/MM/YYYY"
                               placeholder="Data Inicial"
                  ></date-picker>
                </div>
              </div>
              <div class="col-md-6 mg-t--1 mg-md-t-0">
                <div class="form-group mg-md-l--1 bd-t-0-force">
                  <label class="form-control-label">
                    Data Final <span class="tx-danger">*</span></label
                  >
                  <date-picker lang="pt-br"
                               v-model="formFilterConcludedProcesses.endDate"
                               name="toPickedDate"
                               format="DD/MM/YYYY"
                               placeholder="Data Final"
                  ></date-picker>
                </div>
              </div>
            </div>
            <button
              class="btn btn-primary btn-block mg-t-10"
              @click.prevent="submitFilterConcludedProcesses"
            >
              <i class="icon ion-funnel tx-bold"></i> FILTRAR
            </button>
          </div>
        </div>
      </template>
      <template slot="footer">
        <button
          type="button"
          @click="showCompletedProcessesDrawer = false"
          class="btn btn-primary"
        >
          Fechar
        </button>
      </template>
    </Drawer>

    <ProcessForm
      v-if="showProcess"
      :class="{ showHeader: showHeader }"
      v-on:fecharProcesso="reloadProcesses"
    />
    <FabMenu/>
  </div>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import DatePicker from "vue2-datepicker";
import Drawer from "@/components/Drawer.vue";
import ProcessForm from "@/views/operational/imp/Form.vue";
import ProcessesTable from "@/views/operational/imp/Desk.ProcessesTable.vue";
import DeskStateModal from "@/views/operational/imp/Desk.State.Modal.vue";

import FabMenu from "@/components/FabMenu.vue";

import {mapActions, mapState} from "vuex";
import * as labelsProcesses from "@/labels/labels.processes.js";
import moment from "moment";
import Swal from "sweetalert2";
import {EventBus} from "@/events/EventBus.js";
import MainHeader from "@/components/MainHeader/index.vue";
import TwoSelect from "./TwoSelect";

export default {
  name: "operational-importation-desk",
  components: {
    MainHeader,
    "desk-state-modal": DeskStateModal,
    DatePicker,
    Drawer,
    ProcessForm,
    ProcessesTable,
    "two-select": TwoSelect,
    FabMenu,
  },
  data() {
    return {
      modal: {
        deskState: {
          show: false,
        },
      },
      mainTitle: "Telão Operacional",
      modelTitle: "Telão Operacional",
      showTable: true,
      showHeader: false,
      showFilterDrawer: false,
      showColumnsDrawer: false,
      showLegendDrawer: false,
      showCompletedProcessesDrawer: false,
      showSearch: false,
      searchTerms: "",
      searchColumns: "",
      filter: {
        selectedDateRange: {
          from: "",
          to: "",
        },
        selectedDateField: "",
        selectedTeams: [],
        selectedCustomsBrokers: [],
      },
      formFilterConcludedProcesses: {
        dateField: null,
        startDate: null,
        endDate: null,
        customersIds: null,
      },
      customersActivesOptions: [],
      processessConcludedFiltered: false,
      findProcessByIdentification: "",
      data: new Array(),
      tableKey: "",
      columnsModel: [
        "identification",
        "step",
        "status",
        "customerRef",
        "customer",
        "acquirer",
        "importer",
        "exporter",
        "manufacturer",
        "seller",
        "customBroker",
        "account",
        "importerRef",
        "anotherRef",
        "invoice",
        "invoiceDate",
        "customBrokerRef",
        "cifValue",
        "cifValueCurrency",
        "applicationOfGoods",
        "fiscalBenefit",
        "fiscalBenefitDescription",
        "icmsDescription",
        "incoterm",
        "wayOfTransport",
        "typeOfBoarding",
        "billOfLading",
        "vehicle",
        "placeOfLoading",
        "freightForwarder",
        "customsEnclosureURFEntrance",
        "customsEnclosure",
        "conveyor",
        "generalWarehouse",
        "ceMercante",
        "afrmmValue",
        "afrmmStatus",
        "stepSpreadsheet",
        "stepInsurance",
        "stepInsuranceSolved",
        "stepInsuranceValue",
        "stepInsuranceValueResponsability",
        "stepCashRequest",
        "stepCashRequestSolved",
        "originalsDocumentsDate",
        "originalsDocumentsDateSolved",
        "originalsBillOfLadingDate",
        "originalsBillOfLadingDateSolved",
        "originalsInvoiceDate",
        "originalsInvoiceDateSolved",
        "originalsPackingListDate",
        "originalsPackingListDateSolved",
        "conferenceDocumentsSolved",
        "conferencePackingListSolved",
        "conferenceBillOfLadingSolved",
        "conferenceInvoiceSolved",
        "diNumber",
        "diRegistryDate",
        "diTypingDate",
        "diResourcefulnessDate",
        "diChannel",
        "billingNFsDate",
        "billingNFsDateSolved",
        "billingNFsEmissionDate",
        "billingNFsEmissionDateSolved",
        "billingBillCustomerDate",
        "billingBillCustomerDateSolved",
        "billingEmailDate",
        "billingEmailDateSolved",
        "billingSendDate",
        "billingCustomBrokerFinishDate",
        "billingSendCompanyDescription",
        "billingSendTrackingDescription",
        "billingDataConferenceDate",
        "datesEstimatedGoodsReadinesDate",
        "datesETD",
        "datesETA",
        "datesEstimatedCustomerDeliverDate",
        "datesGoodsReadinesDate",
        "datesDepartureDate",
        "datesArrivalDate",
        "datesGoodsPresenceDate",
        "datesInspectionOfTheDIDate",
        "datesGoodsLoadingDate",
        "datesCustomerDeliverDate",
        "datesInspectionMAPADate",
        "datesInspectionMAPADateSolved",
        "datesReleaseMAPADate",
        "datesReleaseMAPADateSolved",
        "datesLinkInTheEnclosureDate",
        "datesLinkInTheEnclosureDateSolved",
        "datesBillOfLadingReleaseDate",
        "datesSiscargaState",
        "datesSiscargaDate",
        "datesIsThereDTCDTATransfer",
        "datesDTCDTA",
        "datesThereIsDesova",
        "datesEnclosureBillingDate",
        "datesComexDate",
        "created",
        "legacyCreated",
        "freetime",
        "customer.isMinimalComissionPercentual",
        "hasInvoices",
        "documentsSentForExchange",
        "statusCustomBroker"
      ],
      columnsMirror: [
        "identification",
        "account",
        "step",
        "customer",
        "exporter",
        "invoice",
        "customerRef",
        "customsEnclosure",
        "billOfLading",
        "datesETA",
        "datesETD",
        "datesDepartureDate",
        "datesArrivalDate",
      ],
      columns: [
        "identification",
        "account",
        "step",
        "customer",
        "exporter",
        "duimp",
        "invoice",
        "customerRef",
        "customsEnclosure",
        "billOfLading",
        "datesETA",
        "datesETD",
        "datesDepartureDate",
        "datesArrivalDate",
      ],
      statusFilters: [],
      datesFields: [
        "stepSpreadsheet",
        "stepInsurance",
        "stepCashRequest",
        "originalsDocumentsDate",
        "originalsBillOfLadingDate",
        "originalsInvoiceDate",
        "originalsPackingListDate",
        "diNumber",
        "diRegistryDate",
        "diTypingDate",
        "diResourcefulnessDate",
        "billingNFsDate",
        "billingBillCustomerDate",
        "billingEmailDate",
        "billingSendDate",
        "billingCustomBrokerFinishDate",
        "billingDataConferenceDate",
        "datesEstimatedGoodsReadinesDate",
        "datesETD",
        "datesETA",
        "datesEstimatedCustomerDeliverDate",
        "datesGoodsReadinesDate",
        "datesDepartureDate",
        "datesArrivalDate",
        "datesGoodsPresenceDate",
        "datesInspectionOfTheDIDate",
        "datesGoodsLoadingDate",
        "datesCustomerDeliverDate",
        "datesInspectionMAPADate",
        "datesReleaseMAPADate",
        "datesLinkInTheEnclosureDate",
        "datesBillOfLadingReleaseDate",
        "datesSiscargaState",
        "datesSiscargaDate",
        "datesDTCDTA",
        "datesThereIsDesova",
        "datesEnclosureBillingDate",
        "datesComexDate",
        "created",
        "legacyCreated",
      ],
      labels: {
        id: {
          label: "ID",
        },
        ...labelsProcesses.labels[0],
      },
      customsBrokers: [],
      teams: [],
      deskStates: [],
      keyword: "",
      stepsColumns: {
        /* producao */
        7: [
          "alerts",
          "identification",
          "step",
          "status",
          "customerRef",
          "customer",
          "exporter",
          "duimp",
          "invoice",
          "incoterm",
          "typeOfBoarding",
          "stepSpreadsheet",
          "stepInsurance",
          "datesEstimatedGoodsReadinesDate",
        ],
        /* licenciamento */
        11: [
          "alerts",
          "identification",
          "step",
          "status",
          "customerRef",
          "customer",
          "exporter",
          "customBroker",
          "duimp",
          "invoice",
          "incoterm",
          "wayOfTransport",
          "typeOfBoarding",
          "billOfLading",
          "freightForwarder",
          "customsEnclosure",
          "stepSpreadsheet",
          "stepInsurance",
          "datesEstimatedGoodsReadinesDate",
          "datesETD",
          "datesETA",
        ],
        /* booking */
        1: [
          "alerts",
          "identification",
          "step",
          "status",
          "customerRef",
          "customer",
          "exporter",
          "customBroker",
          "duimp",
          "invoice",
          "incoterm",
          "wayOfTransport",
          "typeOfBoarding",
          "billOfLading",
          "freightForwarder",
          "customsEnclosure",
          "stepSpreadsheet",
          "stepInsurance",
          "datesEstimatedGoodsReadinesDate",
          "datesETD",
          "datesETA",
        ],
        /* transito */
        2: [
          "alerts",
          "identification",
          "step",
          "status",
          "customerRef",
          "customer",
          "exporter",
          "customBroker",
          "duimp",
          "invoice",
          "incoterm",
          "typeOfBoarding",
          "billOfLading",
          "vehicle",
          "freightForwarder",
          "customsEnclosure",
          "stepCashRequest",
          "originalsDocumentsDate",
          "datesETA",
          "freetime",
          "datesDepartureDate",
          "datesIsThereDTCDTATransfer",
        ],
        /* desembaraco */
        3: [
          "alerts",
          "identification",
          "step",
          "status",
          "customerRef",
          "customer",
          "exporter",
          "customBroker",
          "duimp",
          "invoice",
          "typeOfBoarding",
          "billOfLading",
          "customsEnclosure",
          "conveyor",
          "stepCashRequest",
          "diNumber",
          "diRegistryDate",
          "diChannel",
          "datesArrivalDate",
          "freetime",
          "datesGoodsPresenceDate",
        ],
        /* carregamento */
        4: [
          "alerts",
          "identification",
          "step",
          "status",
          "customerRef",
          "customer",
          "exporter",
          "duimp",
          "invoice",
          "typeOfBoarding",
          "customsEnclosure",
          "conveyor",
          "diResourcefulnessDate",
          "billingNFsDate",
          "billingNFsEmissionDate",
          "datesArrivalDate",
          "freetime",
          "datesSiscargaState",
          "datesComexDate",
        ],
        /* encerramento */
        5: [
          "alerts",
          "identification",
          "step",
          "customer",
          "status",
          "customBroker",
          "duimp",
          "typeOfBoarding",
          "customsEnclosure",
          "datesCustomerDeliverDate",
          "revenuesReleaseBLAWBState",
          "revenuesStorageState",
          "revenuesTransportState",
          "revenuesOtherExpenses",
          "billingTerm",
          "expectedDeliveryTime",
        ],
        /* pendencias */
        10: [
          "alerts",
          "identification",
          "step",
          "status",
          "customerRef",
          "customer",
          "exporter",
          "duimp",
          "invoice",
          "incoterm",
          "typeOfBoarding",
          "conveyor",
        ],
        /* concluido */
        6: [
          "alerts",
          "identification",
          "step",
          "status",
          "customerRef",
          "customer",
          "exporter",
          "duimp",
          "invoice",
          "incoterm",
          "typeOfBoarding",
          "billOfLading",
          "diNumber",
          "diResourcefulnessDate",
          "diChannel",
          "billingBillCustomerDate",
          "datesDepartureDate",
          "datesArrivalDate",
          "datesGoodsLoadingDate",
          "datesCustomerDeliverDate",
        ],
        /* cancelamento */
        9: [
          "alerts",
          "identification",
          "step",
          "status",
          "customerRef",
          "customer",
          "exporter",
          "duimp",
          "invoice",
          "incoterm",
          "typeOfBoarding",
          "billOfLading",
          "datesDepartureDate",
          "datesArrivalDate",
          "billOfLading",
          "diNumber",
          "diResourcefulnessDate",
          "diChannel",
          "datesGoodsLoadingDate",
          "datesCustomerDeliverDate",
          "billingBillCustomerDate",
        ],
      },
      changeToDefaultColumns: true,
    };
  },
  computed: {
    ...mapState({
      steps: (state) => state.ProcessStore.steps.filter((step) => {
        return !(step.id === 11 && !(config.env.API_DEFAULT.host === "http://localhost:7777" || config.env.API_DEFAULT.host === "http://dev.webservice.gscmx.prime.com.vc:7777"));
      }),
      processes: (state) => state.ProcessStore.processes,
      allProcesses: (state) => state.ProcessStore.allProcesses,
      process: (state) => state.ProcessStore.activeProcess,
      showProcess: (state) => state.ProcessStore.showProcess,
      processesFilterIds: (state) => state.ProcessStore.processesFilterIds,
      stateSteps: (state) => state.ProcessStore.stateSteps,
      stateColumns: (state) => state.ProcessStore.stateColumns,
      socket: (state) => state.ProcessStore.socket,
      openedProcesses: (state) => state.ProcessStore.openedProcesses,
      credentials: (state) => state.credentials,
    }),

    listOfDatesFields() {
      const data = new Array();

      this.datesFields.forEach((item) => {
        const itemContent = this.labels[item];

        if (itemContent.type === "date") {
          data.push({
            label: itemContent.label,
            attribute: itemContent.attribute,
          });
        }
      });

      return data;
    },

    listColumnsModel() {
      return this.columnsModel.filter((item) => {
        const itemContent = this.labels[item];
        if (itemContent.label !== undefined) {
          return (
            itemContent.label
              .toLowerCase()
              .indexOf(this.searchColumns.toLowerCase()) > -1
          );
        }

        return false;
      });
    },
  },
  methods: {
    ...mapActions("ProcessStore", [
      "setProcessesFilterIds",
      "getStateSteps",
      "getStateColumns",
      "connectWs",
    ]),
    reloadProcesses(value) {
      // this.searchProcesses('searchFilterComplete')
      // this.loadProcesses(this.$router.currentRoute.query.stepsIds);
    },
    closeModalDeskState(type) {
      this.modal.deskState.show = false;
    },
    toggleHeader() {
      this.showHeader = !this.showHeader;
    },

    async checkActiveStep(step) {
      const stateSteps = await this.getStateSteps();

      return stateSteps.includes(step);
    },
    async findActiveCustomers() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/company/findAllActives`
        );

        if (response.data.data) {
          response.data.data.forEach((customer) => {
            if (customer.isCustomer) {
              this.customersActivesOptions.push({
                label: customer.completeName,
                attribute: customer.id,
              });
            }
          });
        }
      } catch (e) {
        Swal.fire(
          "Filtrar processos concluídos",
          "Erro ao buscar clientes ativos!",
          "error"
        );
      }
    },
    async submitFilterConcludedProcesses() {
      if (
        this.formFilterConcludedProcesses.dateField == null ||
        this.formFilterConcludedProcesses.startDate == null
      ) {
        Swal.fire(
          "Filtrar processos concluídos",
          "Você precisa informar um campo de data e um período para efetuar o filtro!",
          "error"
        );

        this.showCompletedProcessesDrawer = false;
      } else {
        this.$store.commit("setLoading", {
          show: true,
          label: "Estado da Mesa",
          description: "Carregandos os processos concluídos...",
        });

        try {
          const startDate = `${moment(
            this.formFilterConcludedProcesses.startDate
          ).format("YYYY-MM-DD")}T00:00:00`;

          const endDate = null;

          if (this.formFilterConcludedProcesses.endDate) {
            const endDate = `${moment(
              this.formFilterConcludedProcesses.endDate
            ).format("YYYY-MM-DD")}T23:59:00`;
          }

          let customersIds = null;

          if (
            this.formFilterConcludedProcesses.customersIds &&
            this.formFilterConcludedProcesses.customersIds.length > 0
          ) {
            customersIds = this.formFilterConcludedProcesses.customersIds
              .map((item) => item.attribute)
              .join(",");
          }

          let url = `${config.env.API_DEFAULT.host}/newImportProcess?steps=6&columns=customer,forwarder,conveyor,customBroker,manufacturer&startDate=${startDate}&dateField=${this.formFilterConcludedProcesses.dateField.attribute}`;

          if (endDate != null) {
            alert(endDate);
            url += `&endDate=${endDate}`;
          }

          if (customersIds != null) {
            url += `&customersIds=${customersIds}`;
          }

          const response = await Axios.post(url);

          this.$store.commit("ProcessStore/SET_FILTERED_PROCESSES", []);
          this.$store.commit("ProcessStore/SET_PROCESSES", response.data.data);

          this.$store.commit("setLoading", {
            show: false,
          });

          Swal.fire(
            "Telão Operacional",
            "Processos concluídos filtrados com sucesso!",
            "success"
          );

          this.columns = this.stepsColumns[6];

          this.formFilterConcludedProcesses = {
            dateField: null,
            startDate: null,
            endDate: null,
            customersIds: null,
          };

          this.showCompletedProcessesDrawer = false;
        } catch (e) {
          Swal.fire(
            "Telão Operacional",
            `Houve algum problema ao tentar carregar os processos: ${e}`,
            "error"
          );

          this.$store.commit("setLoading", {
            show: false,
          });
        }
      }
    },
    async loadProcesses(steps = "") {
      this.$store.commit("setLoading", {
        show: true,
        label: "Aguarde...",
        description: "Preparando os processos para a operação...",
      });

      try {
        const response = await this.$store.dispatch("ProcessStore/getSteps");
        if (response !== undefined && response && response.success) {
          if (!steps && steps === "") {
            // this.$store.commit('ProcessStore/SET_PROCESSES', []);
          } else {
            const {columns} = this.$router.currentRoute.query;

            const response2 = await this.$store.dispatch(
              "ProcessStore/getProcesses",
              {steps, columns}
            );

            if (!response2.success) {
              if (
                response.error.response.status !== undefined &&
                response.error.response.status !== 401
              ) {
                Swal.fire(
                  "Telão Operacional",
                  `Houve algum problema ao tentar carregar os passos: ${response2.error}`,
                  "error"
                );
              }
            }

            // else this.searchProcesses();
          }
        } else if (
          response.error.response.status !== undefined &&
          response.error.response.status !== 401
        ) {
          Swal.fire(
            "Telão Operacional",
            `Houve algum problema ao tentar carregar os passos: ${response.error}`,
            "error"
          );
        }
      } catch (err) {
        Swal.fire(
          "Telão Operacional",
          `Houve algum problema ao tentar carregar os passos: ${err}`,
          "error"
        );
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    sortTable() {
      this.columns = [
        ...this.columnsModel.filter((item) =>
          this.columnsMirror.includes(item)
        ),
      ];
    },
    searchObject(object, terms) {
      let shouldReturn = false;

      if (
        object !== null &&
        object !== undefined &&
        object.customer !== null &&
        object.customer !== undefined &&
        object.customer.completeName !== null &&
        object.customer.completeName !== undefined
      ) {
        delete object.customer.procedures;
      }

      for (const key in object) {
        const value = object[key];

        if (!shouldReturn && typeof value === "string") {
          shouldReturn = value.toLowerCase().search(terms.toLowerCase()) > -1;
        } else if (!shouldReturn && typeof value === "object") {
          shouldReturn = this.searchObject(value, terms);
        } else if (!shouldReturn && typeof value === "number") {
          shouldReturn =
            value
              .toString()
              .toLowerCase()
              .search(terms.toString().toLowerCase()) > -1;
        }
      }
      return shouldReturn;
    },

    thereIsDateFilter() {
      if (
        this.$route.query.from !== undefined &&
        this.$route.query.from !== null &&
        this.$route.query.to !== undefined &&
        this.$route.query.to !== null &&
        this.$route.query.selectedDateField !== undefined &&
        this.$route.query.selectedDateField !== null
      ) {
        this.filter.selectedDateRange.from = this.$route.query.from;
        this.filter.selectedDateRange.to = this.$route.query.to;
        this.filter.selectedDateField = this.$route.query.selectedDateField;

        return true;
      }
      if (
        this.filter.selectedDateRange.from &&
        this.filter.selectedDateRange.to &&
        this.filter.selectedDateField
      ) {
        return true;
      }
      return false;
    },

    searchProcesses(type, place) {
      if (
        this.processesFilterIds == null ||
        this.processesFilterIds == "" ||
        this.processesFilterIds == undefined
      ) {
        const self = this;
        const ref = this.searchTerms;
        let results = [];
        const processesList =
          this.allProcesses.length > 0 ? this.allProcesses : this.processes;
        this.$store.commit("ProcessStore/SET_FILTERED_PROCESSES", []);

        if (processesList !== null) {
          if (type === "searchFilter" && ref === "") {
            this.$store.commit("ProcessStore/SET_FILTERED_PROCESSES", []);
          } else if (type === "searchFilter") {
            results = processesList.filter((item) => {
              let shouldReturn = false;
              shouldReturn = this.searchObject(item, ref);

              return shouldReturn;
            });

            if (results.length == 0) {
              Swal.fire(
                "Filtros da Telão Operacional",
                `Nenhum Processo de Importação foi encontrado com o termo <b>${ref}</b>`,
                "error"
              );
            } else {
              Swal.fire(
                "Filtros da Telão Operacional",
                `Existe(m) <b>${results.length}</b> Processo(s) de Importação com o termo <b>${ref}</b>: `,
                "success"
              );
            }
          } else {
            results = this.searchFilterComplete(type, processesList, place);
          }

          this.$store.commit("ProcessStore/SET_FILTERED_PROCESSES", results);
        }
      }
    },

    searchFilterComplete(type, results, place) {
      let data = [];
      let from = null;
      let to = null;

      if (
        !this.filter.selectedDateRange.from &&
        this.filter.selectedDateRange.to
      ) {
        Swal.fire(
          "Filtros da Telão Operacional",
          "Você precisa preencher a <b>Data Inicial</b>.",
          "error"
        );
        return null;
      }
      if (
        this.filter.selectedDateRange.from &&
        !this.filter.selectedDateRange.to
      ) {
        Swal.fire(
          "Filtros da Telão Operacional",
          "Você precisa preencher a <b>Data Final</b>.",
          "error"
        );
        return null;
      }
      if (
        this.filter.selectedDateRange.from &&
        this.filter.selectedDateRange.to &&
        !this.filter.selectedDateField
      ) {
        Swal.fire(
          "Filtros da Telão Operacional",
          "Você precisa selecionar o <b>Campo do Filtro</b> dos Filtros Diponíveis para que o campo seja filtrado no processo de acordo com as datas selecionadas.",
          "error"
        );
        return null;
      }
      if (
        this.filter.selectedDateRange.from &&
        this.filter.selectedDateRange.to &&
        this.filter.selectedDateField
      ) {
        from = moment(this.filter.selectedDateRange.from).format("Y-MM-DD");
        to = moment(this.filter.selectedDateRange.to).format("Y-MM-DD");
      }

      let filterDone = false;
      data = results.filter((item) => {
        let returnDate = true;
        let returnTeam = true;
        let returnCustomsBrokers = true;

        if (
          from !== null &&
          to !== null &&
          this.filter.selectedDateField !== null
        ) {
          filterDone = true;
          if (
            item[this.filter.selectedDateField.attribute] !== null &&
            item[this.filter.selectedDateField.attribute] !== "" &&
            item[this.filter.selectedDateField.attribute]
          ) {
            const date = moment(
              item[this.filter.selectedDateField.attribute]
            ).format("Y-MM-DD");
            if (date >= from && date <= to) returnDate = true;
            else returnDate = false;
          } else {
            returnDate = false;
          }
        }

        if (
          this.filter !== null &&
          this.filter.selectedTeams !== null &&
          this.filter.selectedTeams !== undefined &&
          this.filter.selectedTeams.length > 0
        ) {
          filterDone = true;

          if (item.customer !== null && item.customer !== undefined) {
            returnTeam =
              this.filter.selectedTeams.filter(
                (team) =>
                  team.id === item.customerTeamId ||
                  team.id === item.customerTeamOrderId ||
                  team.id === item.customerTeamAdvisoryId
              ).length > 0;
          }
        }

        if (
          this.filter !== null &&
          this.filter.selectedCustomsBrokers !== null &&
          this.filter.selectedCustomsBrokers !== undefined &&
          this.filter.selectedCustomsBrokers.length > 0
        ) {
          filterDone = true;
          returnCustomsBrokers =
            this.filter.selectedCustomsBrokers.filter(
              (customBroker) =>
                item.customBroker !== null &&
                customBroker.completeName === item.customBroker
            ).length > 0;
        }

        return returnDate && returnTeam && returnCustomsBrokers;
      });

      if (place === undefined && filterDone) {
        if (data.length == 0) {
          Swal.fire(
            "Filtros da Telão Operacional",
            "Nenhum Processo de Importação foi encontrado.",
            "error"
          );
        } else {
          Swal.fire(
            "Filtros da Telão Operacional",
            `Processo(s) de Importação encontrado(s): <b>${data.length}</b>`,
            "success"
          );
        }
      }

      return data;
    },
    clearSearchFilterComplete(type) {
      this.$store.commit("ProcessStore/SET_FILTERED_PROCESSES", []);

      if (type === "selectedDateRange") {
        this.filter.selectedDateRange.from = "";
        this.filter.selectedDateRange.to = "";
        this.filter.selectedDateField = "";
      }

      if (type === "selectedDateRange") {
      }
    },
    clearSearch() {
      this.searchTerms = "";
      this.$store.commit("ProcessStore/SET_FILTERED_PROCESSES", []);

      if (this.$route.query.selectedTeams) {
        this.selectedTeams = this.$route.query.selectedTeams
          .split(",")
          .map((item) => parseInt(item));
      } else if (this.$store.state.credentials.data.person.team !== null) {
        this.filter.selectedTeams = [];
        this.filter.selectedTeams.push(
          this.$store.state.credentials.data.person.team
        );
        this.searchProcesses("searchFilterComplete");
      }
    },
    async findProcessByIdentificationSearch() {
      if (this.findProcessByIdentification == "") {
        Swal.fire(
          this.mainTitle,
          "A referência não pode ser nula ou vazia",
          "error"
        );
        return;
      }

      if (this.process) {
        this.socket.send(
          JSON.stringify({
            action: config.env.WEBSOCKET.actions.CLOSE_PROC,
            data: {
              [this.process.id]: this.$store.state.credentials.data.person.name,
            },
          })
        );
      }

      this.$store.commit("ProcessStore/HIDE_PROCESS");

      const response = await this.$store.dispatch(
        "ProcessStore/findProcessByIdentification",
        this.findProcessByIdentification
      );

      if (!response.success) {
        Swal.fire(
          this.mainTitle,
          response.error.response.data.message,
          "error"
        );
        return;
      }

      if (!this.openedProcesses[response.data.id]) {
        this.socket.send(
          JSON.stringify({
            action: config.env.WEBSOCKET.actions.OPEN_PROC,
            data: {
              [response.data.id]:
              this.$store.state.credentials.data.person.name,
            },
          })
        );
      }
    },
    findProcessByIdentificationClearSearch() {
      this.findProcessByIdentification = "";
      this.$store.commit("ProcessStore/SET_FILTERED_PROCESSES", []);
    },
    newProcess() {
      Swal.fire({
        title: "Novo Processo de Importação",
        text: "Você tem certeza que deseja criar um novo Processo de Importação?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "##23BF08",
        cancelButtonColor: "#dc3545",
        confirmButtonText: "Sim, quero criar!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.$store.commit("ProcessStore/HIDE_PROCESS");
          this.$store.commit("ProcessStore/NEW_PROCESS");
        }
      });
    },
    selectDeskStates(state) {
      this.changeToDefaultColumns = false;

      if (state.steps !== null) {
        this.statusFilters = state.steps.map((item) => parseInt(item));
        this.loadProcesses(state.steps.toString());
      }

      if (state.columns !== null) {
        this.columns = state.columns;
        this.columnsMirror = state.columns;
      }

      if (state.filter !== null) {
        this.filter = state.filter;

        if (
          this.filter.selectedDateRange.from !== null &&
          this.filter.selectedDateRange.to !== null &&
          this.filter.selectedDateField !== null
        ) {
          this.searchProcesses("searchFilterComplete");
        } else {
          this.searchProcesses();
        }
      }
    },

    onChangeColumns(newVal) {
      this.columns = [...newVal];
      this.columnsMirror = [...newVal];
    },

    deleteDeskStates(state) {
      Swal.fire({
        title: "Você tem certeza?",
        html: `Se você excluir o estado <strong>${state.name}</strong>, não será possível recuperá-lo para uso posterior.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((willContinue) => {
        if (willContinue.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Estado da Mesa",
            description: `Excluindo o estado ${state.name}...`,
          });

          Axios.delete(state._links.self.href)
            .then((response) => {
              this.$store.commit("setLoading", {
                show: false,
              });
              Swal.fire(
                "Estado da Mesa",
                `O estado da mesa <strong>${state.name}</strong> foi excluído com sucesso!`,
                "success"
              );
              this.loadDeskStates();
            })
            .catch((error) => {
              this.$store.commit("setLoading", {
                show: false,
              });
              if (error.response.status === 409) {
                Swal.fire(
                  "Estado da Mesa",
                  `Não foi possível excluir a ${this.modelTitle}, porque ela já está em uso por algum lançamento financeiro.`,
                  "error"
                );
              } else {
                Swal.fire("Estado da Mesa", error.message, "error");
              }
            });
        }
      });
    },
    loadDeskStates() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Estado da Mesa",
        description: "Carregandos os estados disponíveis no seu perfil...",
      });

      Axios.get(
        `${config.env.API_DEFAULT.host}/users/${this.$store.state.credentials.data.person.id}/deskStates`
      )
        .then((response) => {
          this.deskStates = response.data._embedded.deskStates;
          this.$store.commit("setLoading", {
            show: false,
          });
        })
        .catch((error) => {
          this.$store.commit("setLoading", {
            show: false,
          });
          Swal.fire(
            "Perfis",
            `Houve algum problema ao tentar carregar os Perfis de Telão Operacional cadastrados pelo usuário: ${error.response}`,
            "error"
          );
        });
    },

    async findImportProcessesByKeyword(newTab) {
      if (!this.keyword) {
        Swal.fire(
          "Pesquisa Geral",
          "Você precisa digitar algo para ser pesquisado.",
          "error"
        );
        return false;
      }

      const response = await this.$store.dispatch(
        "ProcessStore/findImportProcessesByKeyword",
        this.keyword
      );
      if (response !== undefined && response !== null && response.success) {
        this.$store.commit("ProcessStore/HIDE_PROCESS");

        if (response.data.data.length > 1) {
          this.$store.commit("ProcessStore/SET_PROCESSES", response.data.data);

          Swal.fire(
            "Pequisa Geral",
            `Foram encontrados ${response.data.data.length} processos nesta pesquisa.`,
            "success"
          );
          this.$router.push("/operational/imp/desk?showProcess=false");

          // if (newTab) {
          //     var routeData = this.$router.resolve({
          //         name: 'operational-imp-desk',
          //         query: {
          //             showProcess: "false",
          //             stepsIds: ""
          //         }
          //     });
          // } else {
          //     this.$router.push("/operational/imp/desk?stepsIds=&showProcess=true");
          //     this.statusFilters = [];
          // }
        } else if (response.data.data.length === 1) {
          if (
            this.openedProcesses[response.data.data[0].id] &&
            this.openedProcesses[response.data.data[0].id].user !=
            this.credentials.data.person.name
          ) {
            this.$store.commit("ProcessStore/SET_NOCLICK", true);
          }
          this.$store.commit("ProcessStore/SET_ACTIVE", response.data.data[0]);
          this.$router.push("/operational/imp/desk?showProcess=true");
        } else {
          Swal.fire(
            "Pesquisa Geral",
            `Nenhum resultado foi encontrado com o termo <strong>${this.keyword}</strong>.`,
            "error"
          );
        }
      } else Swal.fire("Pesquisa Geral", response.message, "error");
    },

    findImportProcessesByKeywordClearSearch() {
      this.keyword = "";
      this.$store.commit("ProcessStore/SET_FILTERED_PROCESSES", []);
    },
  },

  async mounted() {
    this.connectWs();

    this.$store.commit("defaultLayoutDisabled");
    this.$store.commit("ProcessStore/SET_FILTERED_PROCESSES", []);

    this.$store.commit("setLoading", {
      show: true,
      label: "Aguarde...",
      description: "Preparando os processos para a operação...",
    });

    if (
      this.$router.currentRoute.query.filteredByReport == null &&
      !this.$router.currentRoute.query.filteredByReport
    ) {
      if (
        this.stateSteps.length > 0 &&
        this.stateColumns != null &&
        this.stateColumns != ""
      ) {
        this.$router.replace({
          query: {
            stepsIds: this.stateSteps.join(","),
            columns: this.stateColumns,
          },
        });
      }

      if (this.$route.query.processesIds && this.$route.query.stepsIds) {
        this.loadProcesses(this.$route.query.stepsIds);
        this.setProcessesFilterIds({
          processesIds: this.$route.query.processesIds,
        });
      } else if (this.stateSteps.length > 0) {
        this.statusFilters = this.stateSteps;
        this.loadProcesses(this.stateSteps);
      } else if (this.$route.query.stepsIds) {
        this.statusFilters = this.$route.query.stepsIds
          .split(",")
          .map((item) => parseInt(item));
        this.loadProcesses(this.statusFilters);
      } else this.loadProcesses("");

      if (this.$route.query.columns) {
        this.columns = this.$route.query.columns.split(",");
        this.columnsMirror = this.$route.query.columns.split(",");
      }

      if (this.$route.query.showProcess === true) {
        this.showProcess = true;
        // } else {
        //     this.$store.commit('ProcessStore/SET_PROCESSES', []);
      }

      this.$store.commit("setLoading", {
        show: false,
      });

      Axios.get(`${config.env.API_DEFAULT.host}/teams`).then((response) => {
        this.teams = response.data._embedded.teams;
      });

      Axios.get(
        `${config.env.API_DEFAULT.host}/companies/search/findAllByIsCustomBroker?isCustomBroker=true`
      ).then((response) => {
        this.customsBrokers = response.data._embedded.companies;
      });

      this.loadDeskStates();
      EventBus.$on("operational.imp.Desk.loadDeskStates", () => {
        this.loadDeskStates();
      });

      setTimeout(() => {
        if (this.$route.query.selectedTeams) {
          this.selectedTeams = this.$route.query.selectedTeams
            .split(",")
            .map((item) => parseInt(item));
        } else if (this.$store.state.credentials.data.person.team !== null) {
          this.filter.selectedTeams.push(
            this.$store.state.credentials.data.person.team
          );
          this.searchProcesses("searchFilter", "mounted");
        }
      }, 2000);

      this.findActiveCustomers();

      if (this.$route.query.identification) {
        await this.$store.dispatch(
          "ProcessStore/findProcessByIdentification",
          this.$route.query.identification
        );
      }
    }
  },
  watch: {
    async statusFilters(newVal) {
      this.$store.commit("ProcessStore/SET_STATE_STEPS", newVal);

      if (
        this.$route.query.columns != "" &&
        this.$route.query.columns != null
      ) {
        this.$store.commit(
          "ProcessStore/SET_STATE_COLUMNS",
          this.$route.query.columns
        );
      }

      const stateSteps = await this.getStateSteps();
      this.setProcessesFilterIds({processesIds: ""});

      if (stateSteps.length > 0) {
        if (stateSteps.length === 1 && this.changeToDefaultColumns) {
          this.columns = this.stepsColumns[newVal[0]];
          this.columnsMirror = this.stepsColumns[newVal[0]];
        }

        this.changeToDefaultColumns = true; // para setar as colunas default

        const steps = stateSteps.reduce(
          (sum, item) => sum + (sum == "" ? "" : ",") + item,
          ""
        );

        this.$router.push({
          name: "operational-imp-desk",
          query: {
            selectedTeams: this.$route.query.selectedTeams,
            stepsIds: steps,
            columns: this.$route.query.columns,
            showProcess: this.$route.query.showProcess,
          },
        });

        if (this.thereIsDateFilter()) {
          setTimeout(() => {
            this.searchProcesses("searchFilterComplete");
          }, 1000);
        }
      } else {
        this.$store.commit("ProcessStore/SET_PROCESSES", []);

        this.$router.push({
          name: "operational-imp-desk",
          query: {
            selectedTeams: this.$route.query.selectedTeams,
            stepsIds: "",
            columns: this.$route.query.columns,
            showProcess: this.$route.query.showProcess,
          },
        });
      }
    },

    columns(newVal, oldVal) {
      if (
        this.processesFilterIds == null ||
        this.processesFilterIds == "" ||
        this.processesFilterIds == undefined
      ) {
        if (newVal.length > 0) {
          const columns = newVal.reduce(
            (sum, item) => sum + (sum == "" ? "" : ",") + item,
            ""
          );

          if (newVal.length !== oldVal.length) {
            this.$router.push({
              name: "operational-imp-desk",
              query: {
                selectedTeams: this.$route.query.selectedTeams,
                stepsIds: this.$route.query.stepsIds,
                processesIds: this.$route.query.processesIds,
                columns,
                showProcess: this.$route.query.showProcess,
              },
            });
          }
        } else {
          this.$router.push({
            name: "operational-imp-desk",
            query: {
              selectedTeams: this.$route.query.selectedTeams,
              stepsIds: this.$route.query.stepsIds,
              columns:
                "identification,account,step,customer,exporter,invoice,customerRef,customsEnclosure,billOfLading,datesETA,datesETD, datesDepartureDate, datesArrivalDate",
              showProcess: this.$route.query.showProcess,
            },
          });
        }
      }
    },
    $route(newVal, oldVal) {
      if (newVal.query.stepsIds !== oldVal.query.stepsIds) {
        this.loadProcesses(newVal.query.stepsIds);
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    this.statusFilters = [];
    this.$store.commit("ProcessStore/SET_STATE_STEPS", []);
    this.$store.commit("ProcessStore/SET_PROCESSES", []);
    next();
  },
};
</script>

<style scoped>
.search-item {
  display: flex;
  align-items: center;
}

.search-item label {
  border-bottom: 1px solid rgba(255, 255, 255, 0.33);
  padding: 0 6px;
  margin: 0;
}

.search-item input {
  background: none;
  border: none;
  color: #fff;
  outline: none;
  margin: 0;
}

.search-item input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.v-select {
  margin-top: 0px !important;
  padding: 0px !important;
}

.toggleHeader-enter-active {
  transition: transform 0.5s, opacity 0.5s;
}

.toggleHeader-leave-active {
  transition: transform 0.2s, opacity 0.2s;
}

.toggleHeader-enter,
.toggleHeader-leave-to {
  transform: translateY(-152px);
  opacity: 0;
}

.status-filters.nav-pills .nav-item {
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.status-filters.nav-pills .nav-item input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.status-filters.nav-pills .nav-item .nav-link {
  border-radius: 0;
  background: #fff;
  border: 1px solid #ddd;
  border-right: none;
}

.status-filters.nav-pills .nav-item .nav-link:last-child .nav-link {
  border-right: 1px solid #ddd;
}

.status-filters.nav-pills .nav-item:last-child .nav-link {
  border: 1px solid #ddd; 
}

.status-filters.nav-pills .nav-item .nav-link:last-child .nav-link.active {
  border-right: 1px solid #106dc4;
}

.checkbox span {
  display: block;
  border: 1px solid #ddd;
  padding: 6px;
  border-radius: 2px;
}

.checkbox input[type="checkbox"] {
  width: 1px;
  height: 1px;
  top: 0;
  left: 0;
  opacity: 0;
  display: none;
}

.checkbox input[type="checkbox"]:checked + span {
  border-color: #106dc4;
  color: #106dc4;
  font-weight: 500;
  background-color: rgba(16, 109, 196, 0.1);
}

.checkbox {
  display: block;
  position: relative;
  margin: 0 0 3px 0;
}

.mx-datepicker {
  max-width: 120px;
}

.table-area {
  overflow: auto;
  height: calc(100vh - 54px - 57px - 30px);
  position: relative;
}

.table-area.showHeader {
  height: calc(100vh - 54px - 57px - 30px - 152px);
}

.table-area.showSearch {
  height: calc(100vh - 54px - 57px - 30px - 62px);
}

.table-area.showHeader.showSearch {
  height: calc(100vh - 54px - 57px - 30px - 62px - 152px);
}

.btn-icon.btn-sm > div {
  width: 24px;
  height: 24px;
}

.list-group-item .user-name-address {
  cursor: pointer;
  width: 100%;
}

.menu-desk  {
  background: var(--primary-bg-header);
}

.imput-process-by-identification {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Para Chrome e Safari */
@media screen and (-webkit-min-device-pixel-ratio:0) {
  .imput-process-by-identification::-webkit-inner-spin-button,
  .imput-process-by-identification::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* Para Firefox */
@-moz-document url-prefix() {
  .imput-process-by-identification::-moz-inner-spin-button,
  .imput-process-by-identification::-moz-outer-spin-button {
    -moz-appearance: none;
    margin: 0;
  }
}

</style>
