import Vue from 'vue';
import Router from 'vue-router';

import OcrWrapper from '@/views/original-documents/Wrapper.vue';
import RecentOriginalDocuments from '@/views/original-documents/RecentOriginalDocuments.vue';
import ToRevisionOriginalDocuments from '@/views/original-documents/ToRevisionOriginalDocuments.vue';
import ApprovedOriginalDocuments from '@/views/original-documents/ApprovedOriginalDocuments.vue';
import RejectedOriginalDocuments from '@/views/original-documents/RejectedOriginalDocuments.vue';
import SearchOriginalDocuments from '@/views/original-documents/SearchOriginalDocuments.vue';
import CreatedOriginalDocuments from '@/views/original-documents/CreatedOriginalDocuments.vue';
import InvoicesWrapper from '@/views/original-documents/invoices/Wrapper.vue';
import IndexInvoices from '@/views/original-documents/invoices/IndexInvoices.vue';
import PoWrapper from '@/views/original-documents/purchase-orders/Wrapper.vue';
import IndexPos from '@/views/original-documents/purchase-orders/IndexPos.vue';
import SystemConfiguration from '@/views/configuration/Index.vue';
import ConfigDatabaseUsersTab from '@/components/Configuration/tabs/ConfigDatabaseUsersTab.vue';
import NewCalendarView from '@/views/operational/calendar/NewCalendarView.vue';
import Login from './views/Login.vue';
import Profile from './views/configuration/users/Profile.vue';
import AccountsList from './views/configuration/accounts/List.vue';
import AccountsForm from './views/configuration/accounts/Form.vue';

import CompaniesForm from './views/general/companies/Form.vue';
import CompaniesList from './views/general/companies/List.vue';

import CompaniesReportCustomers from './views/general/companies/reports/Companies.Report.Customers.vue';

import ContactsList from './views/general/contacts/List.vue';
import ContactsForm from './views/general/contacts/Form.vue';

import MovimentTermsForm from './views/finances/movimentTerms/Form.vue';
import MovimentTermsList from './views/finances/movimentTerms/List.vue';

import OperationalImpDesk from './views/operational/imp/Desk.vue';

import OperationalEventsList from './views/operational/events/List.vue';

import OperationalStepsList from './views/operational/steps/List.vue';
import OperationalStepsForm from './views/operational/steps/Form.vue';

import DIForm from './views/operational/import-declaration/Form.vue';
import DIList from './views/operational/import-declaration/List.vue';

import LIList from './views/operational/import-licences/List.vue';

import AppointmentTypesForm from './views/billing/appointmentTypes/Form.vue';
import AppointmentTypesList from './views/billing/appointmentTypes/List.vue';
import InvoicesList from './views/billing/invoices/List.vue';
import InvoicesForm from './views/billing/invoices/Form.vue';

import ChartOfAcountsForm from './views/finances/chartOfAccounts/Form.vue';
import ChartOfAcountsList from './views/finances/chartOfAccounts/List.vue';

import BankAccountsForm from './views/finances/bankAccounts/Form.vue';
import BankAccountsList from './views/finances/bankAccounts/List.vue';
import MovimentsTransfersForm from './views/finances/moviments/transfers/Form.vue';
import MovimentsForm from './views/finances/moviments/Form.vue';
import MovimentsList from './views/finances/moviments/List.vue';

import ConfigurationTeamsList from './views/configuration/teams/List.vue';
import ConfigurationTeamsForm from './views/configuration/teams/Form.vue';

import ConfigurationUsersForm from './views/configuration/users/Form.vue';
import ConfigurationUsersList from './views/configuration/users/List.vue';

import ConfigurationGruposList from './views/configuration/groups/List.vue';
import ConfigurationGruposForm from './views/configuration/groups/Form.vue';

import ConfigurationPrevilegiesList from './views/configuration/previlegies/List.vue';
import ConfigurationPrevilegiesForm from './views/configuration/previlegies/Form.vue';
import OperationalReportImpGeneral from './views/operational/reports/imp/Operational.Report.Imp.General.vue';
import OperationalReportClosure from "@/views/operational/reports/imp/Operational.Report.Closure.vue";
import OperationalReportImpChannels from './views/operational/reports/imp/Operational.Report.Imp.Channel.vue';
import OperationalReportImpIPE from './views/operational/reports/imp/Operational.Report.Imp.IPE.vue';
import OperationalReportInsurance from './views/operational/reports/imp/Operational.Report.Imp.Insurance.vue';
import OperationalReportSiscoserv1 from './views/operational/reports/Operational.Report.Siscoserv1.vue';
import OperationalReportInconsistencies from './views/operational/reports/imp/Operational.Report.Inconsistencies.vue';
import OperationalReportCommissioned from './views/operational/reports/imp/Operational.Report.Commissioned.vue';
import ControlPanel from './views/operational/control-panel/ControlPanel.vue';

import CrmWrapper from './components/crm/Wrapper.vue';
import CrmContacts from './views/crm/Contacts.vue';
import CrmContactWrapper from './components/crm/ContactWrapper.vue';
import CrmCustomerWrapper from './components/crm/CustomerWrapper.vue';
import AddContact from './components/crm/AddContact.vue';
import AddCustomer from './components/crm/AddCustomer.vue';
import CrmCustomers from './views/crm/Customers.vue';
import CrmReports from './views/crm/Report.vue';
import CrmNps from './views/crm/Nps.vue';
import Retrospective from './views/utilities/Retrospective/RetrospectiveComponent.vue';
import MyAccount from './views/configuration/accounts/MyAccount.vue';
import RegistrationsOperational from './views/crm/registrations/Index.vue';

import Error503 from './views/errors/503.vue';
import Error403 from './views/errors/403.vue';
import InactiveAPI from './views/errors/InactiveAPI.vue';

import NewRecoveryPassword from './views/configuration/users/NewRecoveryPassword.vue';
import VerifyCodeRecoveryPassword from './views/configuration/users/VerifyCodeRecoveryPassword.vue';
import ChangePassword from './views/configuration/users/ChangePassword.vue';
import CreateEvent from './views/operational/events/CreateEvent.vue';
import UpdateEvent from './views/operational/events/UpdateEvent.vue';
import PartnerIntegrations from './views/operational/integration/PartnerIntegrations.vue';

import ConfigurationAccessGroups from './views/configuration/accessGroups/Index.vue';
import StoreConfigurationAccessGroups from './views/configuration/accessGroups/Store.vue';
import UpdateConfigurationAccessGroups from './views/configuration/accessGroups/Update.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {path: '/', name: 'index', component: ControlPanel},
    {path: '/login', name: 'login', component: Login},
    {
      path: '/recuperarSenha',
      name: 'RecoveryPassword',
      component: NewRecoveryPassword,
    },
    {
      path: '/verificarCodigo',
      name: 'VerifyCodeRecoveryPassword',
      component: VerifyCodeRecoveryPassword,
    },
    {
      path: '/alterarSenha/:pin',
      name: 'ChangePassword',
      component: ChangePassword,
    },
    {path: '/dashboard', name: 'dashboard', component: ControlPanel},
    {path: '/meus-dados', name: 'MyAccount', component: MyAccount},
    {path: '/configuration/profile', name: 'profile', component: Profile},
    {
      path: '/configuration/accounts',
      name: 'accounts-list',
      component: AccountsList,
    },
    {
      path: '/configuration/accounts/:action/:id?',
      name: 'accounts-form',
      component: AccountsForm,
    },
    {
      path: '/configuration/users',
      name: 'users-list',
      component: ConfigurationUsersList,
    },
    {
      path: '/configuration',
      name: 'configuration',
      component: SystemConfiguration,
    },
    {
      path: '/configuration/access-groups',
      name: 'access-groups',
      component: ConfigurationAccessGroups,
    },
    {
      path: '/configuration/add-access-groups',
      name: 'add-access-groups',
      component: StoreConfigurationAccessGroups,
    },
    {
      path: '/configuration/access-groups/:id',
      name: 'update-access-groups',
      component: UpdateConfigurationAccessGroups,
    },
    {
      path: '/configuration/users/:action/:id?',
      name: 'users-form',
      component: ConfigurationUsersForm,
    },
    {
      path: '/configuration/groups',
      name: 'groups-list',
      component: ConfigurationGruposList,
    },
    {
      path: '/configuration/groups/:action/:id?',
      name: 'groups-form',
      component: ConfigurationGruposForm,
    },
    {
      path: '/configuration/previlegies',
      name: 'previlegies-list',
      component: ConfigurationPrevilegiesList,
    },
    {
      path: '/configuration/previlegies/:action/:id?',
      name: 'previlegies-form',
      component: ConfigurationPrevilegiesForm,
    },
    {
      path: '/configuration/teams',
      name: 'teams-list',
      component: ConfigurationTeamsList,
    },
    {
      path: '/configuration/teams/:action/:id?',
      name: 'teams-form',
      component: ConfigurationTeamsForm,
    },

    {
      path: '/general/companies/:action/:id?',
      name: 'companies-form',
      component: CompaniesForm,
    },
    {
      path: '/general/companies/list/type/:type',
      name: 'companies-list',
      component: CompaniesList,
    },
    {
      path: '/general/reports/companies',
      name: 'companies-reports-customers',
      component: CompaniesReportCustomers,
    },

    {
      path: '/general/contacts',
      name: 'contacts-list',
      component: ContactsList,
    },
    {
      path: '/general/contacts/:action/:id?',
      name: 'contacts-form',
      component: ContactsForm,
    },

    {
      path: '/finances/bankAccounts/:action/:id?',
      name: 'bankAccounts-form',
      component: BankAccountsForm,
    },
    {
      path: '/finances/bankAccounts/',
      name: 'bankAccounts-list',
      component: BankAccountsList,
    },
    {
      path: '/finances/moviments/transfers/:action/:id?',
      name: 'moviments-transfers-form',
      component: MovimentsTransfersForm,
    },
    {
      path: '/finances/moviments/:moviments/:action/:id?',
      name: 'moviments-form',
      component: MovimentsForm,
    },
    {
      path: '/finances/list/:moviments',
      name: 'moviments-list',
      component: MovimentsList,
    },
    {
      path: '/finances/movimentTerms/:type/:action/:id?',
      name: 'movimentTerms-form',
      component: MovimentTermsForm,
    },
    {
      path: '/finances/movimentTerms/:type/',
      name: 'movimentTerms-list',
      component: MovimentTermsList,
    },
    {
      path: '/finances/chartOfAccounts/:action/:id?',
      name: 'chartOfAccounts-form',
      component: ChartOfAcountsForm,
    },
    {
      path: '/finances/chartOfAccounts',
      name: 'chartOfAccounts-list',
      component: ChartOfAcountsList,
    },
    {
      path: '/billing/appointmentTypes/:action/:id?',
      name: 'billing-appointmentTypes-form',
      component: AppointmentTypesForm,
    },
    {
      path: '/billing/appointmentTypes/',
      name: 'billing-appointmentTypes-list',
      component: AppointmentTypesList,
    },
    {
      path: '/billing/invoices/',
      name: 'billing-invoices-list',
      component: InvoicesList,
    },
    {
      path: '/billing/invoices/:action/:id?',
      name: 'billing-invoices-form',
      component: InvoicesForm,
    },

    {
      path: '/operational/imp/desk',
      name: 'operational-imp-desk',
      component: OperationalImpDesk,
    },
    // { path: '/operational/events/:action/:id?', name: 'operational-events-form', component: OperationalEventsForm },
    {
      path: '/operational/events',
      name: 'operational-events-list',
      component: OperationalEventsList,
    },
    {
      path: '/operational/events/create',
      name: 'create-event',
      component: CreateEvent,
    },
    {
      path: '/operational/events/update/:id',
      name: 'update-event',
      component: UpdateEvent,
    },
    {
      path: '/operational/configuration/steps/:action/:id?',
      name: 'operational-steps-form',
      component: OperationalStepsForm,
    },
    {
      path: '/operational/configuration/steps',
      name: 'operational-steps-list',
      component: OperationalStepsList,
    },

    {
      path: '/operational/importDeclaration/:action/:id?',
      name: 'operational-imp-declaration-form',
      component: DIForm,
    },
    {
      path: '/operational/importDeclaration',
      name: 'operational-imp-declaration-list',
      component: DIList,
    },

    {
      path: '/operational/importLicences',
      name: 'operational-licences-list',
      component: LIList,
    },

    {
      path: '/operational/control-panel',
      name: 'control-panel',
      component: ControlPanel,
    },

    {
      path: '/operational/reports/imp/general',
      name: 'operational-reports-imp-general',
      component: OperationalReportImpGeneral,
    },
    {
      path: '/operational/reports/imp/closure',
      name: 'operational-reports-imp-closure',
      component: OperationalReportClosure,
    },
    {
      path: '/operational/reports/imp/ipe',
      name: 'operational-reports-imp-ipe',
      component: OperationalReportImpIPE,
    },
    {
      path: '/operational/reports/imp/insurance',
      name: 'operational-reports-imp-insurance',
      component: OperationalReportInsurance,
    },
    {
      path: '/operational/reports/imp/channels',
      name: 'operational-reports-imp-channels',
      component: OperationalReportImpChannels,
    },

    {
      path: '/operational/reports/siscoserv1',
      name: 'operational-reports-siscoserv-1',
      component: OperationalReportSiscoserv1,
    },
    {
      path: '/operational/reports/inconsistencies',
      name: 'operational-reports-inconsistencies',
      component: OperationalReportInconsistencies,
    },
    {
      path: '/operational/reports/commissioneds',
      name: 'operational-reports-commissioned',
      component: OperationalReportCommissioned,
    },
    {
      path: '/operational/reports/calendar',
      name: 'operational-financial-calendar',
      component: NewCalendarView,
    },

    {
      path: '/operational/integration/partner-integrations',
      name: 'operational-partner-integrations',
      component: PartnerIntegrations,
    },

    {
      path: '/utilities/userBank',
      name: 'utilities-userbank-list',
      component: ConfigDatabaseUsersTab,
    },
    // {
    //     path: "/utilities/userBank/:action/:id?",
    //     name: "utilities-userbank-form",
    //     component: UtilitiesUserBankForm,
    // },

    // CRM
    {
      path: '/crm',
      component: CrmWrapper,
      children: [
        {
          path: 'contatos',
          name: 'crm-home',
          component: CrmContacts,
          children: [
            {
              path: 'adicionar',
              name: 'CrmAddContact',
              component: AddContact,
            },
            {
              path: ':id',
              name: 'CrmContact',
              component: CrmContactWrapper,
            },
          ],
        },
        {
          path: 'clientes',
          name: 'crm-clientes',
          component: CrmCustomers,
          children: [
            {
              path: 'adicionar',
              name: 'CrmAddCustomer',
              component: AddCustomer,
            },
            {
              path: ':id',
              name: 'CrmCustomer',
              component: CrmCustomerWrapper,
            },
          ],
        },
        {
          path: 'relatorios',
          name: 'crm-relatorios',
          component: CrmReports,
        },
        {
          path: 'nps',
          name: 'crm-nps',
          component: CrmNps,
        },
        {
          path: 'cadastros/:company',
          name: 'cadastros-operacionais',
          component: RegistrationsOperational,
          meta: { ignoreCrmSidebar: true }
        },
      ],
    },


    // Invoices
    {
      path: '/invoices',
      component: InvoicesWrapper,
      children: [
        {
          path: '',
          name: 'IndexInvoices',
          component: IndexInvoices,
          meta: {
            pageTitle: 'Invoices',
            disableSearch: false,
          },
        },
      ],
    },

    // Purchase Orders
    {
      path: '/ordens-de-compra',
      component: PoWrapper,
      children: [
        {
          path: '',
          name: 'IndexPos',
          component: IndexPos,
          meta: {
            pageTitle: 'Ordens de Compra',
            disableSearch: false,
          },
        },
      ],
    },

    // Documentos Originais
    {
      path: '/documentos-originais',
      component: OcrWrapper,
      children: [
        {
          path: 'pesquisa',
          name: 'SearchOriginalDocuments',
          component: SearchOriginalDocuments,
          meta: {
            pageTitle: 'Busca de Documentos',
            showUploadButton: false,
            isDocumentSearch: true,
          },
        },
        {
          path: 'recentes',
          name: 'RecentOriginalDocuments',
          component: RecentOriginalDocuments,
          meta: {
            pageTitle: 'Envio de Documentos',
            showUploadButton: true,
          },
        },
        {
          path: 'revisao',
          name: 'ToRevisionOriginalDocuments',
          component: ToRevisionOriginalDocuments,
          meta: {
            pageTitle: 'Documentos para Revisar',
            showUploadButton: false,
            documentType: 'COMPLETE',
          },
        },
        {
          path: 'aprovados',
          name: 'ApprovedOriginalDocuments',
          component: ApprovedOriginalDocuments,
          meta: {
            pageTitle: 'Documentos Aprovados',
            showUploadButton: false,
            documentType: 'APPROVED',
          },
        },
        {
          path: 'rejeitados',
          name: 'RejectedOriginalDocuments',
          component: RejectedOriginalDocuments,
          meta: {
            pageTitle: 'Documentos Rejeitados',
            showUploadButton: false,
            documentType: 'REJECTED',
          },
        },
        {
          path: 'finalizados',
          name: 'CreatedOriginalDocuments',
          component: CreatedOriginalDocuments,
          meta: {
            pageTitle: 'Documentos Finalizados',
            showUploadButton: false,
            documentType: 'CREATED',
          },
        },
      ],
    },

    {
      path: '/annual-report',
      name: 'Retrospective',
      component: Retrospective,
    },

    {path: '/error/503/:url?', name: 'error-503', component: Error503},
    {path: '/error/403/:url?', name: 'error-403', component: Error403},
    {path: '/error/InactiveAPI', name: 'InactiveAPI', component: InactiveAPI},
  ],
});

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}