<template>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" :width="size" :height="size">
<path d="m438-338 226-226-57-57-169 169-84-84-57 57 141 141Zm42 258q-139-35-229.5-159.5T160-516v-244l320-120 320 120v244q0 152-90.5 276.5T480-80Zm0-84q104-33 172-132t68-220v-189l-240-90-240 90v189q0 121 68 220t172 132Zm0-316Z"/>
</svg>
</template>

<script>
export default {
  name: 'VerifiedUserIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
