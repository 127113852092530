<template>
  <div>
    <slot name="activator" :on="clickActivator" :opened="open"></slot>

    <transition name="vmodal">
      <div
        v-if="open"
        class="v-modal"
      >
        <div
          class="v-modal-container"
          :class="classModify"
          :style="{
            maxWidth: width + 'px',
            minHeight: height + '%',
          }"
        >
          <div class="v-modal-header">
            <div class="v-modal-title flex ai-c fgap2">
              <div>
                <h3>
                  {{ title }}
                </h3>
                <p class="mb-0">{{ description }}</p>
              </div>

              <div class="slot-header" v-if="$scopedSlots.header">
                <slot name="header"></slot>
              </div>
            </div>
            <div
              v-if="!disableClose"
              class="v-modal-close"
              @click.prevent="closeModal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>
          </div>

          <div
            class="v-modal-content"
            :class="[{ 'has-padding': contentPadding }, classModify]"
          >
            <slot name="content" :opened="open"></slot>
          </div>

          <div class="v-modal-footer" v-if="$scopedSlots.footer">
            <slot name="footer" :closeModal="closeModal"></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'VModal',
  data() {
    return {
      open: this.handler,
    };
  },
  props: {
    handler: {
      type: Boolean,
      default: false,
      required: false,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    description: {
      type: String,
      default: '',
      required: true,
    },
    width: {
      type: Number,
      default: 1300,
    },
    height: {
      type: Number,
      default: 50,
    },
    contentPadding: {
      type: Boolean,
      default: true,
    },
    disableClose: {
      type: Boolean,
      default: false,
    },
    classModify: {
      type: String,
      default: "",  
    }
  },
  methods: {
    clickActivator() {
      this.open = true;
    },
    requestClose(e) {
      if (this.open) {
        if (e.keyCode === 27) {
          this.open = false;
          this.$emit('requestClose', true);
          const event = new Event('reloadDocuments');
          dispatchEvent(event);
        }
      }
    },
    closeModal() {
      if (this.open) {
        this.open = false;
        this.$emit('requestClose', true);
        const event = new Event('reloadDocuments');
        dispatchEvent(event);
      }
    },
  },
  computed: {},
  mounted() {
    if (!this.disableClose) {
      window.addEventListener('keydown', this.requestClose);
    }
  },
  beforeDestroy() {
    if (!this.disableClose) {
      window.removeEventListener('keydown', this.requestClose);
    }
  },
  watch: {
    handler(value) {
      this.open = value;
    },
  },
};
</script>

<style>



.v-modal-content.remove-overflow,
.v-modal-container.remove-overflow {
  overflow-y: unset;
  overflow-x: unset;
  overflow: unset;
}

/* Transition */
.vmodal-enter-active,
.vmodal-leave-active {
  transition: all 0.2s ease-in-out 0s;
}

.vmodal-enter,
.vmodal-leave-to {
  opacity: 0;
}

.vmodal-enter-to,
.vmodal-leave {
  opacity: 1;
}

/* Transition */

/* Modal */

.v-modal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.v-modal-container {
  background-color: #fcfcfc;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 85%;
}

.v-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background-color: #dee2e6;
  padding: 10px 20px;
  color: #343a40;
  border-bottom: 1px solid #d6d6d6;
}

.v-modal-title .slot-header,
.v-modal-title .slot-header p {
  color: #343a40;
}

.v-modal-content {
  display: grid;
  overflow: auto;
  height: 100%;
  flex: 1 1 auto;
}

.v-modal-content.has-padding {
  padding: 20px;
}

.v-modal-footer {
  border-top: 1px solid #e2e2e2;
  padding: 20px;
  background-color: white;
}

.v-modal-title h3 {
  margin: 0;
}

.v-modal-title .slot-header p.sub-gray,
.v-modal-title p {
  color: #868ba1;
}

.v-modal-close {
  cursor: pointer;
}

.modal-invoice .mx-datepicker.disabled {
  opacity: unset;
}

.modal-invoice .input-control input:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: unset;
}

/* Modal */
</style>
