<template>
<div class="menu-container">
  <div class="menu-sidebar" v-for="menu in listMenu" :key="menu.title">
    <div class="menu-sector">
      <h5>{{ menu.title }}</h5>
    </div>
    <ul>
      <li v-for="item in menu.items" :key="item.key">
        <a 
          :href="item.router?.link ?? ''" 
          @click.prevent="item?.click && $emit('changeTabMenuSideBar', item.click)" 
          :class="{ active: current === item.key }" 
        >
          <div class="menu-circle">
            <component :is="item.icon || 'ListCustomer'" :size="20" />
          </div>
          <div>
            <p>{{ item.title }}</p>
            <span>{{ item.subtitle }}</span>
          </div>
        </a>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
import ListCustomer from "@/components/MainHeader/icons/ListCustomer.vue";
import ConveyorBeltIcon from "@/components/icons/ConveyorBeltIcon.vue";
import TrolleyIcon from "@/components/icons/TrolleyIcon.vue";
import FactoryIcon from "@/components/icons/FactoryIcon.vue";
import UniversalCurrencyIcon from "@/components/icons/UniversalCurrencyIcon.vue";
import DirectionsBoatIcon from "@/components/icons/DirectionsBoatIcon.vue";
import VerifiedUserIcon from "@/components/icons/VerifiedUserIcon.vue";

export default {
  name: 'menu-sidebar',
  components: {
    ListCustomer,
    ConveyorBeltIcon,
    TrolleyIcon,
    FactoryIcon,
    UniversalCurrencyIcon,
    DirectionsBoatIcon,
    VerifiedUserIcon,
  },
  props: {
    current: {
      type: String,
      default: '',
    },
    listMenu: { 
      type: Array,
      default: () => [],
    },
    iconDefault: {
      type: String,
      default: 'ListCustomer',
    },
  },
  methods: {},
};
</script>

<style scoped>

.menu-container{
  grid-column: span 3;
  padding: 1.25rem 0.75rem;
  border-right: 1px solid #e2e2e2;
  overflow-y: auto;
  overflow-x: hidden;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.menu-sector:not(:first-of-type) {
  margin-top: 1rem;
}

ul a {
  padding: 0.35rem 0.5rem;
  border-radius: 10px;
  font-size: 0.9rem;
  color: var(--header-text-color-hover);
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: bold;

  background: transparent;
  transition: all .2s ease-in-out 0s;
}

ul a:hover,
ul a.active {
  background-color: var(--shortcut-bg-header);
}

ul a.disabled {
  opacity: 0.5;
}

ul a p {
  margin-bottom: -5px;
}

ul a span {
  display: inline-block;
  font-size: 0.8rem;
  color: #6a6a6a;
  font-weight: 400;
  line-height: 0.8rem;
}

h5 {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: black;
}

.menu-circle {
  flex: 0 0 36px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: var(--shortcut-round-bg);
  color: var(--header-text-color-hover);
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--header-text-color-hover);

  transition: all .2s ease-in-out 0s;
}

ul a.active .menu-circle,
ul a:hover .menu-circle {
  background-color: var(--primary-bg-header);
  color: white;
  fill: white;
}
</style>
